         
  
const orthoSca = 0.7;

var camera, // 相机对象4
    cameraPersp,
    cameraPerspL,
    cameraPerspR,
    cameraPerspT,
    cameraPerspB,
    cameraOrtho,
    cameraOrthoL,
    cameraOrthoR,
    cameraOrthoT,
    cameraOrthoB,
    cameraRig,
    cameraTarget, // 相机朝向目标位置,三维空间点对象
    scene, // 场景对象
    renderer, // 渲染器
    toothMaterial, // 牙齿材质
    selectMaterial, // 牙齿材质
    gingivaMaterial, // 牙龈材质
    gingivabaseMaterial, // 牙龈底座材质
    attachMaterial, // 附件材质
    cutterMaterial, // 切割型附件材质
    MAEquipMaterial, // MA装置材质
    light,
    nTotalStep; // 病例总步数
var caseno; // 病例编号--唯一标识符
var parserMaf = {}; // MAF文件解析对象
var ww = window.innerWidth * 0.97, // 浏览器有效页面宽度,不跟着最大化调整,和初始大小有关
    wh = window.innerHeight * 0.81; // 浏览器有效页面高度
var vecTeeth = new Map(); // 牙齿模型容器
var vecTeeth_Root = new Map(); // 牙根模型容器
var vecAttachment = new Map(); // 附件模型容器
var vecCut = new Map(); // 切割模型容器
var vecTorque = new Map(); // 转矩嵴模型容器
var vecHat = new Map(); // 萌出帽模型容器
var vecMAEquip = new Map(); // MA容器
var stepIndex = 0; // 当前所在步数
var mafModel = {}; // MAF模型数据集
var mapStepinfo_Offset = new Map(); // 模型的偏移数据容器,仅牙齿及附件,含切割
var mapStepinfo_Quaternion = new Map(); // 模型的旋转数据容器,仅牙齿及附件,含切割
var mapStepinfo_Scale = new Map(); // 模型的缩放数据容器,仅牙齿
var stepBar = []; // 初始化进度条的格子数量所用的数组
var mafUrl; // MAF文件的链路地址
var bIsMobile = false; // 是否用手机访问页面
var jawDisplayState = 2; // 上,下,双颌的显示状态
var nCameraDistance = 0; // 相机到朝向目标的默认距离,用于视角切换
var controls; // 轨迹球控制器对象
// var ptBoxCenter = new window.THREE.Vector3(0, 0, 0); // 上下颌牙龈整体包围盒中心点

var gingivaBuild = new window.Module.CGingivaBuild();

var funCallbackObj = {};
var displayType = 0; // 0: single; 1: occlusal; 2: i-f contrast; 3: composite; 4: l-f-r; 5: duel

var G_lowGingivaMesh_Base = {};
var G_upperGingivaMesh_Base = {};
var G_lowGingivaMesh = {};
var G_upperGingivaMesh = {};
var G_lowGingivaMesh_Base0 = {};
var G_upperGingivaMesh_Base0 = {};
var G_lowGingivaMesh0 = {};
var G_upperGingivaMesh0 = {};
var geomInitUpper = {};
var geomInitLower = {};

gingivaMaterial = new window.THREE.MeshPhysicalMaterial({
    // color: new window.THREE.Color("rgb(181,105,92)"),//new window.THREE.Color(0xff7a58),
    metalness: 0.20,
    roughness: 0.05,
    clearcoat: 1,
    clearcoatRoughness: 1,
    reflectivity: 1,
    vertexColors: window.THREE.VertexColors,
});

gingivabaseMaterial = new window.THREE.MeshPhysicalMaterial({
    color: new window.THREE.Color("rgb(220,113,113)"), //new window.THREE.Color(0xff7a58),
    metalness: 0.3,
    roughness: 0.9,
    clearcoat: 0,
    clearcoatRoughness: 1,
    reflectivity: 1,
});

toothMaterial = new window.THREE.MeshPhysicalMaterial({
    // color: new window.THREE.Color("rgb(184,170,157)").offsetHSL(0,0,0.025),
    metalness: 0.2,
    roughness: 0.01,
    clearcoat: 1,
    clearcoatRoughness: 1,
    reflectivity: 0.9,
    vertexColors: window.THREE.VertexColors,
});

selectMaterial = new window.THREE.MeshPhysicalMaterial({
    color: new window.THREE.Color("rgb(128,128,255)"),
    metalness: 0.2,
    roughness: 0.01,
    clearcoat: 1,
    clearcoatRoughness: 1,
    reflectivity: 0.9,
});

attachMaterial = new window.THREE.MeshPhysicalMaterial({
    color: new window.THREE.Color(1, 0.5, 0.5).offsetHSL(0, -0.05, -0.05),
    metalness: 0.0,
    roughness: 0.5,
    clearcoat: 0.8,
    clearcoatRoughness: 0.2,
    reflectivity: 0.5,
});

MAEquipMaterial = new window.THREE.MeshPhysicalMaterial({
    color: new window.THREE.Color("rgb(75,115,150)").offsetHSL(0.0, 0.0, -0.1),
    metalness: 0.0,
    roughness: 0.5,
    clearcoat: 0.5,
    clearcoatRoughness: 0.4,
    reflectivity: 0.5,
    transparent: true,
    opacity: 0.75,
    depthWrite: false,
});

cutterMaterial = new window.THREE.MeshPhysicalMaterial({
    color: new window.THREE.Color(0.0, 0.5, 1.0).offsetHSL(0, 0, 0.05),
    metalness: 0.0,
    roughness: 0.5,
    clearcoat: 0.8,
    clearcoatRoughness: 0.2,
    reflectivity: 0.5,
});

function SoftLightChannel(fBase, fBlend) {
    return (fBlend < 0.5) ? (2.0 * fBase * fBlend + fBase * fBase * (1.0 - 2.0 * fBlend)) : (Math.sqrt(fBase) * (2.0 * fBlend - 1.0) + 2.0 * fBase * (1.0 - fBlend));
}

function SoftLight(v3Base, v3Blend) {
    return new window.THREE.Color(SoftLightChannel(v3Base.r, v3Blend.r), SoftLightChannel(v3Base.g, v3Blend.g), SoftLightChannel(v3Base.b, v3Blend.b));
}

function SoftLightOpacity(v3Base, v3Blend, fOpacity) {
    return v3Base.lerp(SoftLight(v3Base, v3Blend), fOpacity);
}

function PinLightChannel(fBase, fBlend) {
    return (fBlend < 0.5) ? Math.min(fBase, 2.0 * fBlend) : Math.max(fBase, 2.0 * (fBlend - 0.5));
}

function PinLight(v3Base, v3Blend) {
    return new window.THREE.Color(PinLightChannel(v3Base.r, v3Blend.r), PinLightChannel(v3Base.g, v3Blend.g), PinLightChannel(v3Base.b, v3Blend.b));
}

function PinLightOpacity(v3Base, v3Blend, fOpacity) {
    return v3Base.lerp(PinLight(v3Base, v3Blend), fOpacity);
}

function smoothstep(a, b, x) {
    if (x <= a) return 0;
    if (x >= b) return 1;
    let t = (x - a) / (b - a);
    return t * t * (3.0 - (2.0 * t));
}

function GingivaGradient(pl, cm_vert, vertexcolordepth, vertexcolor) {
    if (vertexcolor == undefined) vertexcolor = [];
    let len = vertexcolordepth.length;
    if (len === 0) return vertexcolor;
    let weight1, weight2;
    let color = new window.THREE.Color();
    for (let i = 0; i != len; i++) {
        weight1 = smoothstep(-12.0, 0.0, pl.distanceToPoint(cm_vert[i]));
        weight2 = smoothstep(0.001, 9.0, vertexcolordepth[i]);
        color = PinLightOpacity(new window.THREE.Color("rgb(245,180,170)"), new window.THREE.Color("rgb(190,60,60)"), weight1);
        vertexcolor[i] = SoftLightOpacity(color, new window.THREE.Color("rgb(226,226,200)"), 2.2 * weight2);
    }
    return vertexcolor;
}

function ToothGradient(cm_vert, vertexnorm, vertexcurv, vertexcolor) {
    if (vertexcolor == undefined) vertexcolor = [];
    let len = cm_vert.length;
    if (len === 0) return vertexcolor;
    if (len != vertexnorm.length) return vertexcolor;
    if (len != vertexcurv.length) return vertexcolor;

    let box = new window.THREE.Box3();
    box.setFromPoints(cm_vert);

    let simplex = new SimplexNoise();
    let p = new window.THREE.Vector3();
    let q = new window.THREE.Vector3();
    let weight, ndotup, curv, noise;
    let color = new window.THREE.Color();
    const crown = new window.THREE.Color("rgb(245,243,223)").offsetHSL(0.92, 0.20, 0.0);
    const root = new window.THREE.Color("hsl(18,50%,36%)");
    const groove = new window.THREE.Color("rgb(105,97,86)");
    for (let i = 0; i != len; i++) {
        color.copy(crown);
        ndotup = vertexnorm[i].z;
        curv = vertexcurv[i];
        if (ndotup < -0.866 && curv < 0) {
            weight = Math.max(curv, -3) / 3;
            weight *= (ndotup + 0.866) * 7.46;
            weight = (0.2) * smoothstep(0, 1, weight);
            color.lerp(groove, weight);
        }

        q.copy(cm_vert[i]);
        noise = simplex.noise3d(q.x, q.y, q.z) * 0.1;
        box.getParameter(q, p);
        weight = smoothstep(0.5 + noise, 2, p.z);
        vertexcolor[i] = PinLightOpacity(color.clone(), root, weight);

        if (ndotup < -0.707) {
            weight = Math.max(-1, Math.min(0.5, curv));
            weight *= (ndotup + 0.707) / (-0.293);
            vertexcolor[i].multiplyScalar(smoothstep(-10, 10, weight) / 0.5);
        }
    }
}

function IsDentureVacuole(vecStepScale) {
    for (var item of vecStepScale) {
        if (item.x == 0 || item.y == 0 || item.z == 0){
            return true;
        }
        if (item.x != 1 || item.y != 1 || item.z != 1){
            return true;
        }
    };
    return false;
}

// 播放模型动画
export const playModel = function (step) {
    stepIndex = step;
    // console.log(step);
    playTeeth(step);
    playAttachment(step);
    playMAEquipment(step);
    shiftGingivaVisible(step, jawDisplayState);
};
// 切换附件显示与否
export const shiftAttachVisible = function (visible) {
    vecAttachment.forEach(function (value, key) {
        value.visible = visible;
    });
    vecTorque.forEach(function (value, key) {
        value.visible = visible;
    });
    if (visible) {
        playAttachment(stepIndex);
    }
}
// 渲染
function RenderScene(GLRenderer) {
    switch (displayType) {
        case 1: {
            GLRenderer.clear();
            jawDisplayState = 1;
            showShell(vecTeeth, true);
            showShell(vecTeeth_Root, true);
            showShell(vecHat, true);
            showShell(vecAttachment, true);
            showShell(vecTorque, true);
            showShell(vecCut, true);
            playAttachment(stepIndex);
            playMAEquipment(stepIndex);
            showOtherMesh(1);
            G_lowGingivaMesh.visible = false;
            G_lowGingivaMesh_Base.visible = false;
            G_upperGingivaMesh.visible = true;
            G_upperGingivaMesh_Base.visible = true;
            const e1 = camera === cameraPersp ? cameraPerspL.matrixWorld.elements : cameraOrthoL.matrixWorld.elements;
            let offset = new window.THREE.Vector3(e1[4] + e1[8], e1[5] + e1[9], e1[6] + e1[10]).multiplyScalar(50);
            light.position.copy(offset.add(camera === cameraPersp ? cameraPerspL.position : cameraOrthoL.position).normalize().multiplyScalar(50));
            GLRenderer.setViewport(0, 0, ww / 2, wh);
            GLRenderer.render(scene, camera === cameraPersp ? cameraPerspL : cameraOrthoL);
            jawDisplayState = -1;
            showShell(vecTeeth, false);
            showShell(vecTeeth_Root, false);
            showShell(vecHat, false);
            showShell(vecAttachment, false);
            showShell(vecTorque, false);
            showShell(vecCut, false);
            playAttachment(stepIndex);
            playMAEquipment(stepIndex);
            showOtherMesh(-1);
            G_lowGingivaMesh.visible = true;
            G_lowGingivaMesh_Base.visible = true;
            G_upperGingivaMesh.visible = false;
            G_upperGingivaMesh_Base.visible = false;
            const e2 = camera.matrixWorld.elements;
            offset = new window.THREE.Vector3(e2[4] + e2[8], e2[5] + e2[9], e2[6] + e2[10]).multiplyScalar(50);
            light.position.copy(offset.add(camera.position).normalize().multiplyScalar(50));
            GLRenderer.setViewport(ww / 2, 0, ww / 2, wh);
            GLRenderer.render(scene, camera);
        }
        break;
    case 2: {
        GLRenderer.clear();
        playTeeth(0);
        playAttachment(0);
        playMAEquipment(0);
        G_lowGingivaMesh0.visible = (jawDisplayState === -1 || jawDisplayState === 2) ? true : false;
        G_lowGingivaMesh_Base0.visible = (jawDisplayState === -1 || jawDisplayState === 2) ? true : false;
        G_upperGingivaMesh0.visible = (jawDisplayState === 1 || jawDisplayState === 2) ? true : false;
        G_upperGingivaMesh_Base0.visible = (jawDisplayState === 1 || jawDisplayState === 2) ? true : false;
        G_lowGingivaMesh.visible = false;
        G_lowGingivaMesh_Base.visible = false;
        G_upperGingivaMesh.visible = false;
        G_upperGingivaMesh_Base.visible = false;
        GLRenderer.setViewport(0, 0, ww / 2, wh);
        GLRenderer.render(scene, camera);
        playTeeth(nTotalStep - 1);
        playAttachment(nTotalStep - 1);
        playMAEquipment(nTotalStep - 1);
        G_lowGingivaMesh.visible = G_lowGingivaMesh0.visible;
        G_lowGingivaMesh_Base.visible = G_lowGingivaMesh_Base0.visible;
        G_upperGingivaMesh.visible = G_upperGingivaMesh0.visible;
        G_upperGingivaMesh_Base.visible = G_upperGingivaMesh_Base0.visible;
        G_lowGingivaMesh0.visible = false;
        G_lowGingivaMesh_Base0.visible = false;
        G_upperGingivaMesh0.visible = false;
        G_upperGingivaMesh_Base0.visible = false;
        GLRenderer.setViewport(ww / 2, 0, ww / 2, wh);
        GLRenderer.render(scene, camera);
    }
    break;
    case 3: {
        GLRenderer.clear();
        jawDisplayState = 2;
        showMeshSet(vecTeeth, true);
        showMeshSet(vecTeeth_Root, true);
         showMeshSet(vecHat, true);
        showMeshSet(vecAttachment, true);
        showMeshSet(vecTorque, true);
        showMeshSet(vecCut, true);
        playAttachment(stepIndex);
        playMAEquipment(stepIndex);
        showOtherMesh(0);
        G_lowGingivaMesh.visible = true;
        G_lowGingivaMesh_Base.visible = true;
        G_upperGingivaMesh.visible = true;
        G_upperGingivaMesh_Base.visible = true;
        const e1 = camera === cameraPersp ? cameraPerspL.matrixWorld.elements : cameraOrthoL.matrixWorld.elements;
        let offset = new window.THREE.Vector3(e1[4] + e1[8], e1[5] + e1[9], e1[6] + e1[10]).multiplyScalar(50);
        light.position.copy(offset.add(camera === cameraPersp ? cameraPerspL.position : cameraOrthoL.position).normalize().multiplyScalar(50));
        GLRenderer.setViewport(0, wh / 2, ww / 3, wh / 2);
        GLRenderer.render(scene, camera === cameraPersp ? cameraPerspL : cameraOrthoL);
        const e2 = camera.matrixWorld.elements;
        offset = new window.THREE.Vector3(e2[4] + e2[8], e2[5] + e2[9], e2[6] + e2[10]).multiplyScalar(50);
        light.position.copy(offset.add(camera.position).normalize().multiplyScalar(50));
        GLRenderer.setViewport(ww / 3, wh / 2, ww / 3, wh / 2);
        GLRenderer.render(scene, camera);
        const e3 = camera === cameraPersp ? cameraPerspR.matrixWorld.elements : cameraOrthoR.matrixWorld.elements;
        offset = new window.THREE.Vector3(e3[4] + e3[8], e3[5] + e3[9], e3[6] + e3[10]).multiplyScalar(50);
        light.position.copy(offset.add(camera === cameraPersp ? cameraPerspR.position : cameraOrthoR.position).normalize().multiplyScalar(50));
        GLRenderer.setViewport(ww / 1.5, wh / 2, ww / 3, wh / 2);
        GLRenderer.render(scene, camera === cameraPersp ? cameraPerspR : cameraOrthoR);
        jawDisplayState = 1;
        showShell(vecTeeth, true);
        showShell(vecTeeth_Root, true);
        showShell(vecHat, true);
        showShell(vecAttachment, true);
        showShell(vecTorque, true);
        showShell(vecCut, true);
        playAttachment(stepIndex);
        playMAEquipment(stepIndex);
        showOtherMesh(1);
        G_lowGingivaMesh.visible = false;
        G_lowGingivaMesh_Base.visible = false;
        G_upperGingivaMesh.visible = true;
        G_upperGingivaMesh_Base.visible = true;
        const e4 = camera === cameraPersp ? cameraPerspT.matrixWorld.elements : cameraOrthoT.matrixWorld.elements;
        offset = new window.THREE.Vector3(e4[4] + e4[8], e4[5] + e4[9], e4[6] + e4[10]).multiplyScalar(50);
        light.position.copy(offset.add(camera === cameraPersp ? cameraPerspT.position : cameraOrthoT.position).normalize().multiplyScalar(50));
        GLRenderer.setViewport(0, 0, ww / 2, wh / 2);
        GLRenderer.render(scene, camera === cameraPersp ? cameraPerspT : cameraOrthoT);
        jawDisplayState = -1;
        showShell(vecTeeth, false);
        showShell(vecTeeth_Root, false);
        showShell(vecHat, false);
        showShell(vecAttachment, false);
        showShell(vecTorque, false);
        showShell(vecCut, false);
        playAttachment(stepIndex);
        playMAEquipment(stepIndex);
        showOtherMesh(-1);
        G_lowGingivaMesh.visible = true;
        G_lowGingivaMesh_Base.visible = true;
        G_upperGingivaMesh.visible = false;
        G_upperGingivaMesh_Base.visible = false;
        const e5 = camera === cameraPersp ? cameraPerspB.matrixWorld.elements : cameraOrthoB.matrixWorld.elements;
        offset = new window.THREE.Vector3(e5[4] + e5[8], e5[5] + e5[9], e5[6] + e5[10]).multiplyScalar(50);
        light.position.copy(offset.add(camera === cameraPersp ? cameraPerspB.position : cameraOrthoB.position).normalize().multiplyScalar(50));
        GLRenderer.setViewport(ww / 2, 0, ww / 2, wh / 2);
        GLRenderer.render(scene, camera === cameraPersp ? cameraPerspB : cameraOrthoB);
    }
    break;
    case 4: {
        GLRenderer.clear();
        jawDisplayState = 2;
        showMeshSet(vecTeeth, true);
        showMeshSet(vecTeeth_Root, true);
        showMeshSet(vecHat, true);
        showMeshSet(vecAttachment, true);
        showMeshSet(vecTorque, true);
        showMeshSet(vecCut, true);
        playAttachment(stepIndex);
        playMAEquipment(stepIndex);
        showOtherMesh(0);
        G_lowGingivaMesh.visible = true;
        G_lowGingivaMesh_Base.visible = true;
        G_upperGingivaMesh.visible = true;
        G_upperGingivaMesh_Base.visible = true;
        const e1 = camera === cameraPersp ? cameraPerspL.matrixWorld.elements : cameraOrthoL.matrixWorld.elements;
        let offset = new window.THREE.Vector3(e1[4] + e1[8], e1[5] + e1[9], e1[6] + e1[10]).multiplyScalar(50);
        light.position.copy(offset.add(camera === cameraPersp ? cameraPerspL.position : cameraOrthoL.position).normalize().multiplyScalar(50));
        GLRenderer.setViewport(0, 0, ww / 3, wh);
        GLRenderer.render(scene, camera === cameraPersp ? cameraPerspL : cameraOrthoL);
        const e2 = camera.matrixWorld.elements;
        offset = new window.THREE.Vector3(e2[4] + e2[8], e2[5] + e2[9], e2[6] + e2[10]).multiplyScalar(50);
        light.position.copy(offset.add(camera.position).normalize().multiplyScalar(50));
        GLRenderer.setViewport(ww / 3, 0, ww / 3, wh);
        GLRenderer.render(scene, camera);
        const e3 = camera === cameraPersp ? cameraPerspR.matrixWorld.elements : cameraOrthoR.matrixWorld.elements;
        offset = new window.THREE.Vector3(e3[4] + e3[8], e3[5] + e3[9], e3[6] + e3[10]).multiplyScalar(50);
        light.position.copy(offset.add(camera === cameraPersp ? cameraPerspR.position : cameraOrthoR.position).normalize().multiplyScalar(50));
        GLRenderer.setViewport(ww / 1.5, 0, ww / 3, wh);
        GLRenderer.render(scene, camera === cameraPersp ? cameraPerspR : cameraOrthoR);
    }
    break;
    case 5: {
        GLRenderer.clear();
        jawDisplayState = 2;
        showMeshSet(vecTeeth, true);
        showMeshSet(vecTeeth_Root, true);
        showMeshSet(vecHat, true);
        showMeshSet(vecAttachment, true);
        showMeshSet(vecTorque, true);
        showMeshSet(vecCut, true);
        playAttachment(stepIndex);
        playMAEquipment(stepIndex);
        showOtherMesh(0);
        G_lowGingivaMesh.visible = true;
        G_lowGingivaMesh_Base.visible = true;
        G_upperGingivaMesh.visible = true;
        G_upperGingivaMesh_Base.visible = true;
        const e1 = camera === cameraPersp ? cameraPerspL.matrixWorld.elements : cameraOrthoL.matrixWorld.elements;
        let offset = new window.THREE.Vector3(e1[4] + e1[8], e1[5] + e1[9], e1[6] + e1[10]).multiplyScalar(50);
        light.position.copy(offset.add(camera === cameraPersp ? cameraPerspL.position : cameraOrthoL.position).normalize().multiplyScalar(50));
        GLRenderer.setViewport(0, 0, ww / 2, wh);
        GLRenderer.render(scene, camera === cameraPersp ? cameraPerspL : cameraOrthoL);
        const e2 = camera.matrixWorld.elements;
        offset = new window.THREE.Vector3(e2[4] + e2[8], e2[5] + e2[9], e2[6] + e2[10]).multiplyScalar(50);
        light.position.copy(offset.add(camera.position).normalize().multiplyScalar(50));
        GLRenderer.setViewport(ww / 2, 0, ww / 2, wh);
        GLRenderer.render(scene, camera);
    }
    break;
    default: {
        GLRenderer.render(scene, camera);
    }
    }
}
export const render = function () {
    updateScreenSize();
    controls.update();
    RenderScene(renderer);
}
// 初始化数据
export const initStl = function (id, url, isMobile) {
    console.log(url);
    mafUrl = url; // 设定链路
    caseno = id;
    bIsMobile = isMobile; // 设定模式
    if (window.canvasAttr) {
        ww = window.canvasAttr.width;
        wh = window.canvasAttr.height;
    } else {
        ww = window.innerWidth * 0.97;
        wh = window.innerHeight * 0.81;
    }
    let canvascollection = document.getElementsByClassName('scene');
    let canvasscene = canvascollection[0];
    if (renderer != undefined) {
        // while (renderer.domElement.lastChild){
        //     renderer.domElement.removeChild(renderer.domElement.lastChild);
        // }
        // renderer.dispose();
        // renderer = null;
    } else {
        renderer = new window.THREE.WebGLRenderer({
            canvas: canvasscene,
            antialias: true,
            alpha: true,
            logarithmicDepthBuffer: false
        }); // 解决Zfighting
        renderer.setPixelRatio(window.devicePixelRatio); // 提示清晰度
        renderer.shadowMap.enabled = true;
        renderer.shadowMap.type = window.THREE.PCFSoftShadowMap;
        renderer.sortObjects = false;
    }

    renderer.setSize(ww, wh);
    nCameraDistance = bIsMobile ? 750 : 500;
    cameraTarget = new window.THREE.Vector3(0, 0, 0);

    if (cameraPersp == undefined) {
        cameraPersp = new window.THREE.PerspectiveCamera(10, ww / wh, nCameraDistance - 100, nCameraDistance + 100);
        cameraPersp.position.set(0, -nCameraDistance, 0);
        cameraPersp.updateProjectionMatrix();
        cameraPerspL = new window.THREE.PerspectiveCamera().copy(cameraPersp, false);
        cameraPerspR = new window.THREE.PerspectiveCamera().copy(cameraPersp, false);
        cameraPerspT = new window.THREE.PerspectiveCamera().copy(cameraPersp, false);
        cameraPerspB = new window.THREE.PerspectiveCamera().copy(cameraPersp, false);

        cameraOrtho = new window.THREE.OrthographicCamera(-30 * ww / wh, 30 * ww / wh, 30, -30, nCameraDistance - 100, nCameraDistance + 100);
        cameraOrtho.position.set(0, -nCameraDistance, 0);
        cameraOrtho.zoom = orthoSca;
        cameraOrtho.updateProjectionMatrix();
        cameraOrthoL = new window.THREE.OrthographicCamera().copy(cameraOrtho, false);
        cameraOrthoR = new window.THREE.OrthographicCamera().copy(cameraOrtho, false);
        cameraOrthoT = new window.THREE.OrthographicCamera().copy(cameraOrtho, false);
        cameraOrthoB = new window.THREE.OrthographicCamera().copy(cameraOrtho, false);

        cameraRig = new window.THREE.Group();
        cameraRig.add(cameraPersp);
        cameraRig.add(cameraOrtho);
        cameraRig.add(cameraPerspL);
        cameraRig.add(cameraPerspR);
        cameraRig.add(cameraPerspT);
        cameraRig.add(cameraPerspB);
        cameraRig.add(cameraOrthoL);
        cameraRig.add(cameraOrthoR);
        cameraRig.add(cameraOrthoT);
        cameraRig.add(cameraOrthoB);
    } else {
        cameraPersp.near = nCameraDistance - 100;
        cameraPersp.far = nCameraDistance + 100;
        cameraPersp.aspect = ww / wh;
        cameraPersp.updateProjectionMatrix();
        cameraPerspL.copy(cameraPersp, false);
        cameraPerspR.copy(cameraPersp, false);
        cameraPerspT.copy(cameraPersp, false);
        cameraPerspB.copy(cameraPersp, false);
        cameraOrtho.left = -30 * ww / wh;
        cameraOrtho.right = 30 * ww / wh;
        cameraOrtho.near = nCameraDistance - 100;
        cameraOrtho.far = nCameraDistance + 100;
        cameraOrtho.updateProjectionMatrix();
        cameraOrthoL.copy(cameraOrtho, false);
        cameraOrthoR.copy(cameraOrtho, false);
        cameraOrthoT.copy(cameraOrtho, false);
        cameraOrthoB.copy(cameraOrtho, false);
    }
    cameraRig.lookAt(cameraTarget);
    camera = cameraPersp;
    if (scene != undefined) {
        for (let i = scene.children.length - 1; i >= 0; i--) {
            let obj = scene.children[i];
            // scene.remove(obj);
            if (obj !== cameraRig && !obj.isLight) {
                scene.remove(obj);
                if (obj instanceof window.THREE.Mesh) {
                    obj.material.dispose();
                    obj.geometry.dispose();
                }
                obj = null;
            }
        }
        // scene.remove();
    } else {
        scene = new window.THREE.Scene();
        scene.add(cameraRig);
        addShadowedLight(0xe6ebf0);
    }

    ajaxRequest(); // 发送MAF文件解析请求(ajax异步)

    if (controls == undefined) {
        controls = new window.THREE.TrackballControls(camera, canvasscene); // 生成轨迹球场景控制器
    } else {
        controls.object = camera;
        controls.domElement = canvasscene;
    }
    controls.rotateSpeed = 5;
    controls.zoomSpeed = 1;
    controls.panSpeed = 0.3;
    controls.maxDistance = nCameraDistance + 100;
    controls.minDistance = 50;
    controls.dynamicDampingFactor = 0.2;
    controls.position0.set(0, 0, 0);
    controls.up0.set(0, 0, -1); // set a new up vector
    controls.reset();
    controls.staticMoving = true; // 关闭惯性
    controls.mouseButtons = {
        LEFT: window.THREE.MOUSE.ROTATE,
        MIDDLE: window.THREE.MOUSE.ZOOM,
        RIGHT: window.THREE.MOUSE.PAN
    };
    jawDisplayState = 2; // 初始化颌面显示状态
    animate();
};
// 更新屏幕大小
function updateScreenSize() { // 正常来说应该只走onWindowResize,但是无法截获用户调整浏览器屏幕尺寸
    if (window.canvasAttr) {
        ww = window.canvasAttr.width;
        wh = window.canvasAttr.height;
    } else {
        ww = window.innerWidth * 0.97;
        wh = window.innerHeight * 0.81;
    }
    if ((cameraPersp.position.lengthSq()) > 2000 * 2000) { // 缩小限制
        cameraPersp.position.x *= 0.95;
        cameraPersp.position.y *= 0.95;
        cameraPersp.position.z *= 0.95;
    }
    if ((cameraOrtho.position.lengthSq()) > 2000 * 2000) { // 缩小限制
        cameraOrtho.position.x *= 0.95;
        cameraOrtho.position.y *= 0.95;
        cameraOrtho.position.z *= 0.95;
    }
    cameraRig.lookAt(cameraTarget);
    cameraPersp.near = Math.max(10, cameraPersp.position.length() - 100);
    cameraPersp.far = cameraPersp.position.length() + 100;
    cameraOrtho.near = Math.max(1, Math.min(50, cameraPersp.position.length() - 100));
    cameraOrtho.far = Math.max(200, cameraPersp.position.length() + 100);
    switch (displayType) {
        case 1: {
            cameraPersp.aspect = ww / (2.0 * wh);
            cameraOrtho.left = -30 * ww / (2.0 * wh);
            cameraOrtho.right = 30 * ww / (2.0 * wh);
            cameraPerspL.aspect = ww / (2.0 * wh);
            cameraPerspL.near = cameraPersp.near;
            cameraPerspL.far = cameraPersp.far;
            cameraOrthoL.left = -30 * ww / (2.0 * wh);
            cameraOrthoL.right = 30 * ww / (2.0 * wh);
            cameraOrthoL.near = cameraOrtho.near;
            cameraOrthoL.far = cameraOrtho.far;
            cameraPerspL.updateProjectionMatrix();
            cameraOrthoL.updateProjectionMatrix();
        }
        break;
    case 2: {
        cameraPersp.aspect = ww / (2.0 * wh);
        cameraOrtho.left = -30 * ww / (2.0 * wh);
        cameraOrtho.right = 30 * ww / (2.0 * wh);
    }
    break;
    case 3: {
        cameraPersp.aspect = ww / (1.5 * wh);
        cameraOrtho.left = -30 * ww / (1.5 * wh);
        cameraOrtho.right = 30 * ww / (1.5 * wh);
        cameraPerspL.aspect = ww / (1.5 * wh);
        cameraPerspL.near = Math.max(10, cameraPerspL.position.length() - 100);
        cameraPerspL.far = cameraPerspL.position.length() + 100;
        cameraPerspL.updateProjectionMatrix();
        cameraOrthoL.left = -30 * ww / (1.5 * wh);
        cameraOrthoL.right = 30 * ww / (1.5 * wh);
        cameraOrthoL.near = Math.max(10, cameraOrthoL.position.length() - 100);
        cameraOrthoL.far = cameraOrthoL.position.length() + 100;
        cameraOrthoL.updateProjectionMatrix();
        cameraPerspR.aspect = ww / (1.5 * wh);
        cameraPerspR.near = Math.max(10, cameraPerspR.position.length() - 100);
        cameraPerspR.far = cameraPerspR.position.length() + 100;
        cameraPerspR.updateProjectionMatrix();
        cameraOrthoR.left = -30 * ww / (1.5 * wh);
        cameraOrthoR.right = 30 * ww / (1.5 * wh);
        cameraOrthoR.near = Math.max(10, cameraOrthoR.position.length() - 100);
        cameraOrthoR.far = cameraOrthoR.position.length() + 100;
        cameraOrthoR.updateProjectionMatrix();
        cameraPerspT.aspect = ww / wh;
        cameraPerspT.near = Math.max(10, cameraPerspT.position.length() - 100);
        cameraPerspT.far = cameraPerspT.position.length() + 100;
        cameraPerspT.updateProjectionMatrix();
        cameraOrthoT.left = -30 * ww / wh;
        cameraOrthoT.right = 30 * ww / wh;
        cameraOrthoT.near = Math.max(10, cameraOrthoT.position.length() - 100);
        cameraOrthoT.far = cameraOrthoT.position.length() + 100;
        cameraOrthoT.updateProjectionMatrix();
        cameraPerspB.aspect = ww / wh;
        cameraPerspB.near = Math.max(10, cameraPerspB.position.length() - 100);
        cameraPerspB.far = cameraPerspB.position.length() + 100;
        cameraPerspB.updateProjectionMatrix();
        cameraOrthoB.left = -30 * ww / wh;
        cameraOrthoB.right = 30 * ww / wh;
        cameraOrthoB.near = Math.max(10, cameraOrthoB.position.length() - 100);
        cameraOrthoB.far = cameraOrthoB.position.length() + 100;
        cameraOrthoB.updateProjectionMatrix();
    }
    break;
    case 4: {
        cameraPersp.aspect = ww / (3.0 * wh);
        cameraOrtho.left = -30 * ww / (3.0 * wh);
        cameraOrtho.right = 30 * ww / (3.0 * wh);
        cameraPerspL.aspect = ww / (3.0 * wh);
        cameraPerspL.near = Math.max(10, cameraPerspL.position.length() - 100);
        cameraPerspL.far = cameraPerspL.position.length() + 100;
        cameraPerspL.updateProjectionMatrix();
        cameraOrthoL.left = -30 * ww / (3.0 * wh);
        cameraOrthoL.right = 30 * ww / (3.0 * wh);
        cameraOrthoL.near = Math.max(10, cameraOrthoL.position.length() - 100);
        cameraOrthoL.far = cameraOrthoL.position.length() + 100;
        cameraOrthoL.updateProjectionMatrix();
        cameraPerspR.aspect = ww / (3.0 * wh);
        cameraPerspR.near = Math.max(10, cameraPerspR.position.length() - 100);
        cameraPerspR.far = cameraPerspR.position.length() + 100;
        cameraPerspR.updateProjectionMatrix();
        cameraOrthoR.left = -30 * ww / (3.0 * wh);
        cameraOrthoR.right = 30 * ww / (3.0 * wh);
        cameraOrthoR.near = Math.max(10, cameraOrthoR.position.length() - 100);
        cameraOrthoR.far = cameraOrthoR.position.length() + 100;
        cameraOrthoR.updateProjectionMatrix();
    }
    break;
    case 5: {
        cameraPersp.aspect = ww / (2.0 * wh);
        cameraOrtho.left = -30 * ww / (2.0 * wh);
        cameraOrtho.right = 30 * ww / (2.0 * wh);
        cameraPerspL.aspect = ww / (2.0 * wh);
        cameraPerspL.near = cameraPersp.near;
        cameraPerspL.far = cameraPersp.far;
        cameraOrthoL.left = -30 * ww / (2.0 * wh);
        cameraOrthoL.right = 30 * ww / (2.0 * wh);
        cameraOrthoL.near = cameraOrtho.near;
        cameraOrthoL.far = cameraOrtho.far;
        cameraPerspL.updateProjectionMatrix();
        cameraOrthoL.updateProjectionMatrix();
    }
    break;
    default: {
        cameraPersp.aspect = ww / wh;
        cameraOrtho.left = -30 * ww / wh;
        cameraOrtho.right = 30 * ww / wh;
    }
    }
    if (displayType === 1 || displayType === 3) {
        let perspZoom = controls.object.isOrthographicCamera ? controls.object.zoom / orthoSca : controls.object.zoom;
        cameraRig.children.forEach(function (obj) {
            if (obj !== controls.object) {
                if (obj.isPerspectiveCamera) {
                    obj.zoom = perspZoom;
                } else if (obj.isOrthographicCamera) {
                    obj.zoom = perspZoom * orthoSca;
                }
            }
        });
    }
    cameraPersp.updateProjectionMatrix();
    cameraOrtho.updateProjectionMatrix();
    const e = camera.matrixWorld.elements;
    let offset = new window.THREE.Vector3(e[4] + e[8], e[5] + e[9], e[6] + e[10]).multiplyScalar(50);
    light.position.copy(offset.add(camera.position).normalize().multiplyScalar(50));
    renderer.setSize(ww, wh);
    controls.handleResize();
};
// 增加平行光源
function addShadowedLight(color) {
    light = new window.THREE.DirectionalLight(color, 0.48);
    let offset = new window.THREE.Vector3(0, -50, -50);
    offset.add(camera.position).normalize().multiplyScalar(50);
    light.position.copy(offset);
    // light.position.set(0, 50, 50);

    light.castShadow = true;
    light.shadow.camera.near = 10;
    light.shadow.camera.far = 90;
    light.shadow.camera.left = -30;
    light.shadow.camera.right = 30;
    light.shadow.camera.top = 30;
    light.shadow.camera.bottom = -30;
    light.shadow.mapSize.width = 2048;
    light.shadow.mapSize.height = 2048;
    light.shadow.bias = 0.0001;
    light.shadow.radius = 1.5;
    scene.add(light);

    // var helper = new window.THREE.CameraHelper( light.shadow.camera );
    // scene.add( helper );
    var ambientLight = new window.THREE.AmbientLight(color, 0.8);
    scene.add(ambientLight);
}
// 动画渲染函数,原理未知
function animate() {

    render();
    if (funCallbackObj.callback && typeof funCallbackObj.callback === 'function') {
        funCallbackObj.callback();
    }
    requestAnimationFrame(animate);
};
// 切换视角按钮点击事件
export const pointModel = function (btnIndex) {
    if (camera == null) {
        return;
    }

    if (btnIndex === 0) { // 双颌
        jawDisplayState = 2;
        showDoubleJaw();
    } else if (btnIndex === 1) { // 单颌
        if (jawDisplayState === 2 || jawDisplayState === -1) {
            jawDisplayState = 1;
            showSingleJaw(true);
        } else if (jawDisplayState === 1) {
            jawDisplayState = -1;
            showSingleJaw(false);
        }
    } else if (btnIndex === 2) { // 左侧
        cameraPersp.position.set(-1.1 * nCameraDistance, 0, 0);
        cameraPersp.rotation.set(1.57, -1.57, 0);
        cameraPersp.up.x = 0;
        cameraPersp.up.y = 0;
        cameraPersp.up.z = -1;
        cameraOrtho.position.set(-1.1 * nCameraDistance, 0, 0);
        cameraOrtho.rotation.set(1.57, -1.57, 0);
        cameraOrtho.up.x = 0;
        cameraOrtho.up.y = 0;
        cameraOrtho.up.z = -1;
        controls.target.copy(controls.target0);
    } else if (btnIndex === 3) { // 正面
        cameraPersp.position.set(0, -nCameraDistance, 0); // 正面(0, -400, 0)
        cameraPersp.rotation.set(0, 0, 0);
        cameraPersp.up.x = 0;
        cameraPersp.up.y = 0;
        cameraPersp.up.z = -1;
        cameraOrtho.position.set(0, -nCameraDistance, 0); // 正面(0, -400, 0)
        cameraOrtho.rotation.set(0, 0, 0);
        cameraOrtho.up.x = 0;
        cameraOrtho.up.y = 0;
        cameraOrtho.up.z = -1;
        controls.target.copy(controls.target0);
    } else if (btnIndex === 4) { // 右侧
        cameraPersp.position.set(1.1 * nCameraDistance, 0, 0);
        cameraPersp.rotation.set(1.57, 1.57, 0);
        cameraPersp.up.x = 0;
        cameraPersp.up.y = 0;
        cameraPersp.up.z = -1;
        cameraOrtho.position.set(1.1 * nCameraDistance, 0, 0);
        cameraOrtho.rotation.set(1.57, 1.57, 0);
        cameraOrtho.up.x = 0;
        cameraOrtho.up.y = 0;
        cameraOrtho.up.z = -1;
        controls.target.copy(controls.target0);
    } else if (btnIndex === 5) { // 下颌
        jawDisplayState = -1;
        showSingleJaw(false);
        cameraPersp.position.set(0, 0, -nCameraDistance);
        cameraPersp.rotation.set(0, 0, 0);
        cameraPersp.up.x = 0;
        cameraPersp.up.y = 1;
        cameraPersp.up.z = 0;
        cameraOrtho.position.set(0, 0, -nCameraDistance);
        cameraOrtho.rotation.set(0, 0, 0);
        cameraOrtho.up.x = 0;
        cameraOrtho.up.y = 1;
        cameraOrtho.up.z = 0;
        controls.target.copy(controls.target0);
    } else if (btnIndex === 6) { // 上颌
        jawDisplayState = 1;
        showSingleJaw(true);
        cameraPersp.position.set(0, 0, nCameraDistance);
        cameraPersp.rotation.set(0, 0, 0);
        cameraPersp.up.x = 0;
        cameraPersp.up.y = -1;
        cameraPersp.up.z = 0;
        cameraOrtho.position.set(0, 0, nCameraDistance);
        cameraOrtho.rotation.set(0, 0, 0);
        cameraOrtho.up.x = 0;
        cameraOrtho.up.y = -1;
        cameraOrtho.up.z = 0;
        controls.target.copy(controls.target0);
    } else if (btnIndex === 45) {
        jawDisplayState = 2;
        showDoubleJaw();
        cameraPersp.position.set(-nCameraDistance, -nCameraDistance, -nCameraDistance);
        cameraPersp.up.x = 1;
        cameraPersp.up.y = 1;
        cameraPersp.up.z = -2;
        cameraPersp.up.normalize();
        cameraOrtho.position.set(-nCameraDistance, -nCameraDistance, -nCameraDistance);
        cameraOrtho.up.copy(cameraPersp.up);
        controls.target.copy(controls.target0);
    }
    playAttachment(stepIndex);
    playMAEquipment(stepIndex);

    // 看向世界中心
    cameraTarget = new window.THREE.Vector3(0, 0, 0);
    cameraRig.lookAt(cameraTarget);
    cameraPersp.updateProjectionMatrix();
    cameraOrtho.updateProjectionMatrix();
    // camera.updateProjectionMatrix();
    render();
}
// 调试用时间日志输出函数
function getTime() {
    var date1 = new Date();
    var year = date1.getFullYear();
    var month = date1.getMonth() + 1;
    var day = date1.getDate();
    var hours = date1.getHours();
    var minutes = date1.getMinutes();
    var seconds = date1.getSeconds();
    var mSeconds = date1.getMilliseconds();
    return (year + "年" + month + "月" + day + "日" + hours + ":" + minutes + ":" + seconds + "." + mSeconds);
}

// 发送ajax请求,下载并解析maf文件
function ajaxRequest() {
    let url = mafUrl;
    let ajax = null;
    if (window.ActiveXObject) {
        ajax = new window.ActiveXObject("Microsoft.XMLHTTP");
    } else if (window.XMLHttpRequest) {
        ajax = new XMLHttpRequest();
    } else {
        alert("Browser Not Support XMLHTTP");
        return;
    }
    if (ajax != null) {
        Free();
        ajax.onreadystatechange = function () {
            if (this.readyState == 4 && (this.status == 200)) { // 若status为0则是读取本地病例,若status为200则是读取服务器上的病例
                if (this.response && this.response.byteLength && this.response.byteLength > 5) { // console.log("MAF文件已经拿到,开始解析"+getTime());
                    let arrMaf = new Uint8Array(this.response);
                    let strSign = "";
                    let filename = this.responseURL;
                    let nSourceLen = 0;
                    for (let i = 0; i < 6; i++) {
                        strSign += String.fromCharCode(arrMaf[i]);
                    }
                    if (strSign == "AB.SIP") {
                        let hex_length = "";
                        for (let i = 6; i < 10; i++) {
                            let r = arrMaf[i];
                            r = r.toString(16);
                            if (r.length === 1)
                                r = "0" + r;

                            hex_length = r + "" + hex_length;
                        }
                        nSourceLen = parseInt(hex_length, 16);
                        parserMaf.SetFileConfig(filename, nSourceLen);

                        window.LZMA.decompress(arrMaf.slice(6), function on_decompress_complete(result) { // console.log("解析"+getTime());
                            parserMaf.ReadUncompressBuffer(result);
                            ajaxAttachMaf(filename);
                        }, function on_decompress_progress_update(percent) {});
                    } else {
                        nSourceLen = arrMaf.length;
                        parserMaf.SetFileConfig(filename, nSourceLen);
                        parserMaf.ReadUncompressBuffer(arrMaf);

                        ajaxAttachMaf(filename);
                    }
                } else {
                    alert("File Not Exist");
                }
            }
        };

        ajax.open("GET", url, true);
        ajax.responseType = "arraybuffer";

        ajax.setRequestHeader('If-Modified-Since', '0');
        ajax.setRequestHeader("Cache-Control", "no-cache");
        ajax.contentType = ajax.send();
    }
};

function ajaxAttachMaf(filename) {
    let ajax = null;
    if (window.ActiveXObject) {
        ajax = new window.ActiveXObject("Microsoft.XMLHTTP");
    } else if (window.XMLHttpRequest) {
        ajax = new XMLHttpRequest();
    } else {
        alert("Browser Not Support XMLHTTP");
        return;
    }
    if (ajax != null) {
        let arrAttachmentType = [];
        let attachmafname = "";
        let nAttachIndex = 0;
        ajax.onreadystatechange = function () {
            if (this.readyState == 4 && (this.status == 200)) { // 若status为0则是读取本地病例,若status为200则是读取服务器上的病例
                if (this.response && this.response.byteLength && this.response.byteLength > 5) {
                    let arrMaf = new Uint8Array(this.response);
                    // attachmafname = this.responseURL.split("/").reverse()[0];
                    let strSign = "";
                    for (let i = 0; i < 6; i++) {
                        strSign += String.fromCharCode(arrMaf[i]);
                    }
                    if (strSign == "AB.SIP") {
                        let hex_length = "";
                        for (let i = 6; i < 10; i++) {
                            let r = arrMaf[i];
                            r = r.toString(16);
                            if (r.length === 1) r = "0" + r;
                            hex_length = r + "" + hex_length;
                        }

                        window.LZMA.decompress(arrMaf.slice(6), function on_decompress_complete(result) { // console.log("解析"+getTime());
                            let result1 = parserMaf.ReadUncompressAttachMaf(result, attachmafname);
                            if (result1 == 1) console.log("attachment " + attachmafname + " parsed");
                            nAttachIndex++;
                            if (nAttachIndex === arrAttachmentType.length) {

                                ajaxMAMaf(filename);
                            } else {

                                attachmafname = arrAttachmentType[nAttachIndex];

                                if (window.location.href.indexOf('easysmile.magicalign') != -1) {
                                    console.log('es')
                                    ajax.open("GET", "http://easysmile.magicalign.com/output/attachment/" + attachmafname, true);
                                } else if (window.location.href.indexOf('vvsmile.magicalign') != -1) {
                                    console.log('vv')
                                    ajax.open("GET", "http://vvsmile.magicalign.com/output/attachment/" + attachmafname, true);
                                } else if (window.location.href.indexOf('plusbeauty.magicalign') != -1) {
                                    console.log('绮齐美')

                                    ajax.open("GET", "http://plusbeauty.magicalign.com/output/attachment/" + attachmafname, true);
                                }else if (window.location.href.indexOf('smiletrack.magicalign.com') != -1) {
                                    console.log('绮齐美')

                                    ajax.open("GET", "http://smiletrack.magicalign.com/output/attachment/" + attachmafname, true);
                                } 
                                else if (window.location.href.indexOf('203.156.250.44') != -1) {
                                    console.log('203.156.250.44:5000')

                                    ajax.open("GET", "http://203.156.250.44:5000/output/attachment/" + attachmafname, true);
                                } else if (window.location.href.indexOf('jiegeer.magicalign.com') != -1) {
                                    ajax.open("GET", "http://jiegeer.magicalign.com/output/attachment/" + attachmafname, true);
                                }else if (window.location.href.indexOf('cherrysmile.magicalign.com/') != -1) {
                                    ajax.open("GET", "http://cherrysmile.magicalign.com//output/attachment/" + attachmafname, true);
                                }else if (window.location.href.indexOf('moresmile.magicalign.com') != -1) {
                                    ajax.open("GET", "http://moresmile.magicalign.com/output/attachment/" + attachmafname, true);
                                }else if(window.location.href.indexOf('172.168.0.195')!= -1){
                                    ajax.open("GET", "http://172.168.0.195:8081/output/attachment/" + attachmafname, true);
                                }
                                 else {
                                    console.log('zl')
                                    ajax.open("GET", "http://case.magicalign.com/output/attachment/" + attachmafname, true);
                                }
                                ajax.responseType = "arraybuffer";
                                ajax.setRequestHeader('If-Modified-Since', '0');
                                ajax.setRequestHeader("Cache-Control", "no-cache");

                                ajax.contentType = ajax.send();
                            }

                        }, function on_decompress_progress_update(percent) {});
                    }
                }
            }
        };

        arrAttachmentType = parserMaf.GetAttachMafName().split("?");
        console.log(arrAttachmentType);
        if (arrAttachmentType.length !== 0 && arrAttachmentType[0] != "") {
            attachmafname = arrAttachmentType[nAttachIndex];
            if (window.location.href.indexOf('easysmile.magicalign') != -1) {
                console.log('es')
                ajax.open("GET", "http://easysmile.magicalign.com/output/attachment/" + attachmafname, true);
            } else if (window.location.href.indexOf('vvsmile.magicalign') != -1) {
                console.log('vv')
                ajax.open("GET", "http://vvsmile.magicalign.com/output/attachment/" + attachmafname, true);
            } else if (window.location.href.indexOf('plusbeauty.magicalign.com') != -1) {
                console.log('绮齐美')

                ajax.open("GET", "http://plusbeauty.magicalign.com/output/attachment/" + attachmafname, true);
            } else if (window.location.href.indexOf('smiletrack.magicalign.com') != -1) {
                console.log('绮齐美')

                ajax.open("GET", "http://smiletrack.magicalign.com/output/attachment/" + attachmafname, true);
            } else if (window.location.href.indexOf('203.156.250.44') != -1) {
                console.log('203.156.250.44:5000')

                ajax.open("GET", "http://203.156.250.44:5000/output/attachment/" + attachmafname, true);
            } else if (window.location.href.indexOf('jiegeer.magicalign.com') != -1) {
                ajax.open("GET", "http://jiegeer.magicalign.com/output/attachment/" + attachmafname, true);
            } else if (window.location.href.indexOf('cherrysmile.magicalign.com/') != -1) {
                ajax.open("GET", "http://cherrysmile.magicalign.com//output/attachment/" + attachmafname, true);
            }else if (window.location.href.indexOf('moresmile.magicalign.com') != -1) {
                ajax.open("GET", "http://moresmile.magicalign.com/output/attachment/" + attachmafname, true);
            }else if(window.location.href.indexOf('172.168.0.195')!= -1){
                ajax.open("GET", "http://172.168.0.195:8081/output/attachment/" + attachmafname, true);
            }   else {
                console.log('zl')
                ajax.open("GET", "http://case.magicalign.com/output/attachment/" + attachmafname, true);

            }

            ajax.responseType = "arraybuffer";
            ajax.setRequestHeader('If-Modified-Since', '0');
            ajax.setRequestHeader("Cache-Control", "no-cache");
            ajax.contentType = ajax.send();
        } else {
            ajaxMAMaf(filename);
        }
    }
};

function ajaxMAMaf(filename) {
    let ajax = null;
    if (window.ActiveXObject) {
        ajax = new window.ActiveXObject("Microsoft.XMLHTTP");
    } else if (window.XMLHttpRequest) {
        ajax = new XMLHttpRequest();
    } else {
        alert("Browser Not Support XMLHTTP");
        return;
    }
    if (ajax != null) {
        let arrMAEquipmentType = [];
        let MAmafname = "";
        let nMAIndex = 0;
        ajax.onreadystatechange = function () {
            if (this.readyState == 4 && (this.status == 200)) { // 若status为0则是读取本地病例,若status为200则是读取服务器上的病例
                if (this.response && this.response.byteLength && this.response.byteLength > 5) {
                    let arrMaf = new Uint8Array(this.response);
                    let strSign = "";
                    for (let i = 0; i < 6; i++) {
                        strSign += String.fromCharCode(arrMaf[i]);
                    }
                    if (strSign == "AB.SIP") {
                        let hex_length = "";
                        for (let i = 6; i < 10; i++) {
                            let r = arrMaf[i];
                            r = r.toString(16);
                            if (r.length === 1) r = "0" + r;
                            hex_length = r + "" + hex_length;
                        }

                        window.LZMA.decompress(arrMaf.slice(6), function on_decompress_complete(result) { // console.log("解析"+getTime());
                            let result1 = parserMaf.ReadUncompressMAMaf(result, MAmafname);
                            if (result1 == 1) console.log("MA " + MAmafname + " parsed");
                            nMAIndex++;
                            if (nMAIndex === arrMAEquipmentType.length) {
                                parserMAFData(filename, "yes", parserMaf);
                            } else {
                                MAmafname = arrMAEquipmentType[nMAIndex];

                                if (window.location.href.indexOf('easysmile.magicalign') != -1) {
                                    console.log('es')

                                    ajax.open("GET", "http://easysmile.magicalign.com/output/Mana/" + MAmafname, true);
                                } else if (window.location.href.indexOf('plusbeauty.magicalign') != -1) {
                                    console.log('qiqimei')
                                    ajax.open("GET", "http://plusbeauty.magicalign.com/output/Mana/" + MAmafname, true);
                                }else if (window.location.href.indexOf('smiletrack.magicalign.com') != -1) {
                                    console.log('绮齐美')

                                    ajax.open("GET", "http://smiletrack.magicalign.com/output/Mana/" + attachmafname, true);
                                }  else if (window.location.href.indexOf('vvsmile.magicalign') != -1) {
                                    console.log('vv')
                                    ajax.open("GET", "http://vvsmile.magicalign.com/output/Mana/" + MAmafname, true);
                                } else if (window.location.href.indexOf('http://203.156.250.44') != -1) {
                                    console.log('203.156.250.44')
                                    ajax.open("GET", "http://203.156.250.44:5000/output/Mana/" + MAmafname, true);
                                } else if (window.location.href.indexOf('jiegeer.magicalign.com') != -1) {
                                    ajax.open("GET", "http://jiegeer.magicalign.com/output/Mana/" + MAmafname, true);
                                }  else if (window.location.href.indexOf('cherrysmile.magicalign.com/') != -1) {
                                    ajax.open("GET", "http://cherrysmile.magicalign.com//output/Mana/" + MAmafname, true);
                                } else if (window.location.href.indexOf('moresmile.magicalign.com') != -1) {
                                    ajax.open("GET", "http://moresmile.magicalign.com/output/Mana/" + MAmafname, true);
                                }else if(window.location.href.indexOf('172.168.0.195')!= -1){
                                    ajax.open("GET", "http://172.168.0.195:8081/output/Mana/" + MAmafname, true);
                                }  else {
                                    console.log('zl')
                                    ajax.open("GET", "http://case.magicalign.com/output/Mana/" + MAmafname, true);
                                    // ajax.open("GET", "/output/Mana/" + MAmafname, true);
                                }
                                ajax.responseType = "arraybuffer";
                                ajax.setRequestHeader('If-Modified-Since', '0');
                                ajax.setRequestHeader("Cache-Control", "no-cache");
                                ajax.contentType = ajax.send();
                            }

                        }, function on_decompress_progress_update(percent) {});
                    }
                }
            }
        };

        arrMAEquipmentType = parserMaf.GetMAMafName().split("?");
        console.log(arrMAEquipmentType);
        if (arrMAEquipmentType.length !== 0 && arrMAEquipmentType[0] != "") {
            MAmafname = arrMAEquipmentType[nMAIndex];
            if (window.location.href.indexOf('easysmile.magicalign') != -1) {
                console.log('es')
                ajax.open("GET", "http://easysmile.magicalign.com/output/Mana/" + MAmafname, true);
            } else if (window.location.href.indexOf('plusbeauty.magicalign') != -1) {
                console.log('绮齐美')
                ajax.open("GET", "http://plusbeauty.magicalign.com/output/Mana/" + MAmafname, true);
            }
            else if (window.location.href.indexOf('smiletrack.magicalign.com') != -1) {
                console.log('绮齐美')
                ajax.open("GET", "http://smiletrack.magicalign.com/output/Mana/" + MAmafname, true);
            }
             else if (window.location.href.indexOf('vvsmile.magicalign') != -1) {
                console.log('vv')
                ajax.open("GET", "http://vvsmile.magicalign.com/output/Mana/" + MAmafname, true);
            } else if (window.location.href.indexOf('203.156.250.44') != -1) {
                console.log('203.156.250.44')
                ajax.open("GET", "http://203.156.250.44:5000/output/Mana/" + MAmafname, true);
            } else if (window.location.href.indexOf('jiegeer.magicalign.com') != -1) {
                ajax.open("GET", "http://jiegeer.magicalign.com/output/Mana/" + MAmafname, true);
            } else if (window.location.href.indexOf('cherrysmile.magicalign.com/') != -1) {
                ajax.open("GET", "http://cherrysmile.magicalign.com//output/Mana/" + MAmafname, true);
            }else if (window.location.href.indexOf('moresmile.magicalign.com') != -1) {
                ajax.open("GET", "http://moresmile.magicalign.com/output/Mana/" + MAmafname, true);
            }else if(window.location.href.indexOf('172.168.0.195')!= -1){
                ajax.open("GET", "http://172.168.0.195:8081/output/Mana/" + MAmafname, true);
            } 
             else {
                console.log('zl')
                ajax.open("GET", "http://case.magicalign.com/output/Mana/" + MAmafname, true);
                // ajax.open("GET", "/output/Mana/" + MAmafname, true);
            }
            ajax.responseType = "arraybuffer";
            ajax.setRequestHeader('If-Modified-Since', '0');
            ajax.setRequestHeader("Cache-Control", "no-cache");
            ajax.contentType = ajax.send();
        } else {
            parserMAFData(filename, "no", parserMaf);
        }
    }
};

// 解析单步的牙龈数据
function parseGingivaData(parserMaf, i) { // 牙龈数据
    var bGet = false;
    // 公用数据是否读取成功标志
    // var gingivaBuild = new window.Module.CGingivaBuild();
    var nGingivaID = 0;
    var ptVertex = new window.CVector(); // 公用点对象
    var ptFaceIndex = new window.CVector();
    // 公用面对象
    // gingivaBuild.BuildGingivaMesh(mafModel);
    // 公用牙龈编号
    var a = i;
    var vertexnorm = [];
    var vertexcolors = [];
    var vertexcolor = [];
    var geom = null;
    for (; i < a + 1; i++) {
        gingivaBuild.SetGingivaMeshOfStep(mafModel, i);
        let offsetMA = mapStepinfo_Offset.get(0)[i];
        var plUpperBase = new window.THREE.Plane(),
            plLowerBase = new window.THREE.Plane();
        // 上颌牙龈底座
        {
            nGingivaID = i; // 牙龈编号:步骤数+上下颌标识,1为上颌,0为下颌

            var upperGingiva_Base = mafModel.GetUpperGingiva();
            var upperGingivaNum_Base = upperGingiva_Base.GetBaseMeshVertex();
            var upperGingivaFaceNum_Base = upperGingiva_Base.GetBaseMeshFace();
            var upGingivaVertex_Base = [];
            var upGingivaVertexNorm_Base = [];
            var upGingivaFace_Base = [];
            // 顶点
            for (vi = 0; vi < upperGingivaNum_Base; vi++) {
                bGet = upperGingiva_Base.GetBaseVertexXYZ(ptVertex, vi);
                if (bGet) {
                    upGingivaVertex_Base.push(new window.THREE.Vector3(ptVertex.GetX(), ptVertex.GetY(), ptVertex.GetZ()));
                }
                bGet = upperGingiva_Base.GetBaseVertexNorm(ptVertex, vi);
                if (bGet) {
                    upGingivaVertexNorm_Base.push(new window.THREE.Vector3(ptVertex.GetX(), ptVertex.GetY(), ptVertex.GetZ()));
                }
            }
            // console.log("牙龈点" + upperGingivaNum);
            // 面的顶点索引
            for (fi = 0; fi < upperGingivaFaceNum_Base; fi++) {
                bGet = upperGingiva_Base.GetBaseFaceIndex(ptFaceIndex, fi);
                if (bGet) {
                    vertexnorm = [];
                    vertexnorm.push(upGingivaVertexNorm_Base[ptFaceIndex.GetX()]);
                    vertexnorm.push(upGingivaVertexNorm_Base[ptFaceIndex.GetY()]);
                    vertexnorm.push(upGingivaVertexNorm_Base[ptFaceIndex.GetZ()]);
                    upGingivaFace_Base.push(new window.THREE.Face3(ptFaceIndex.GetX(), ptFaceIndex.GetY(), ptFaceIndex.GetZ(), vertexnorm));
                }
            }
            // console.log("牙龈面" + upperGingivaFaceNum);
            upperGingiva_Base = null;
            if (upperGingivaFaceNum_Base > 0) {
                plUpperBase.setFromCoplanarPoints(upGingivaVertex_Base[ptFaceIndex.GetX()], upGingivaVertex_Base[ptFaceIndex.GetY()], upGingivaVertex_Base[ptFaceIndex.GetZ()]);
                G_upperGingivaMesh_Base.geometry.dispose();
                geom = new window.THREE.Geometry();
                geom.vertices = upGingivaVertex_Base;
                geom.faces = upGingivaFace_Base;
                G_upperGingivaMesh_Base.geometry = new window.THREE.BufferGeometry().fromGeometry(geom);
                geom.dispose();
                geom = null;
            }
            vertexnorm.length = 0;
            upGingivaVertex_Base.length = 0;
            upGingivaVertexNorm_Base.length = 0;
            upGingivaFace_Base.length = 0;
            upGingivaVertex_Base = null;
            upGingivaVertexNorm_Base = null;
            upGingivaFace_Base = null;
        }

        // 下颌牙龈底座
        {
            nGingivaID = i; // 牙龈编号:步骤数+上下颌标识,1为上颌,0为下颌

            var lowerGingiva_Base = mafModel.GetLowerGingiva();
            var lowerGingivaNum_Base = lowerGingiva_Base.GetBaseMeshVertex();
            var lowerGingivaFaceNum_Base = lowerGingiva_Base.GetBaseMeshFace();
            var lowGingivaVertex_Base = [];
            var lowGingivaVertexNorm_Base = [];
            var lowGingivaFace_Base = [];
            // 顶点
            for (vi = 0; vi < lowerGingivaNum_Base; vi++) {
                bGet = lowerGingiva_Base.GetBaseVertexXYZ(ptVertex, vi);
                if (bGet) {
                    lowGingivaVertex_Base.push(new window.THREE.Vector3(ptVertex.GetX(), ptVertex.GetY(), ptVertex.GetZ()));
                }
                bGet = lowerGingiva_Base.GetBaseVertexNorm(ptVertex, vi);
                if (bGet) {
                    lowGingivaVertexNorm_Base.push(new window.THREE.Vector3(ptVertex.GetX(), ptVertex.GetY(), ptVertex.GetZ()));
                }
            }

            // 面的顶点索引
            for (fi = 0; fi < lowerGingivaFaceNum_Base; fi++) {
                bGet = lowerGingiva_Base.GetBaseFaceIndex(ptFaceIndex, fi);
                if (bGet) {
                    vertexnorm = [];
                    vertexnorm.push(lowGingivaVertexNorm_Base[ptFaceIndex.GetX()]);
                    vertexnorm.push(lowGingivaVertexNorm_Base[ptFaceIndex.GetY()]);
                    vertexnorm.push(lowGingivaVertexNorm_Base[ptFaceIndex.GetZ()]);
                    lowGingivaFace_Base.push(new window.THREE.Face3(ptFaceIndex.GetX(), ptFaceIndex.GetY(), ptFaceIndex.GetZ(), vertexnorm));
                }
            }

            lowerGingiva_Base = null;
            if (lowerGingivaFaceNum_Base > 0) {
                plLowerBase.setFromCoplanarPoints(lowGingivaVertex_Base[ptFaceIndex.GetX()], lowGingivaVertex_Base[ptFaceIndex.GetY()], lowGingivaVertex_Base[ptFaceIndex.GetZ()]);
                G_lowGingivaMesh_Base.geometry.dispose();
                geom = new window.THREE.Geometry();
                geom.vertices = lowGingivaVertex_Base;
                geom.faces = lowGingivaFace_Base;
                G_lowGingivaMesh_Base.geometry = new window.THREE.BufferGeometry().fromGeometry(geom);
                G_lowGingivaMesh_Base.position.set(offsetMA.x, offsetMA.y, offsetMA.z);
                geom.dispose();
                geom = null;
            }
            vertexnorm.length = 0;
            lowGingivaVertex_Base.length = 0;
            lowGingivaVertexNorm_Base.length = 0;
            lowGingivaFace_Base.length = 0;
            lowGingivaVertex_Base = null;
            lowGingivaVertexNorm_Base = null;
            lowGingivaFace_Base = null;
        }

        // 上颌牙龈
        {
            nGingivaID = i; // 牙龈编号:步骤数+上下颌标识,1为上颌,0为下颌

            var upperGingiva = mafModel.GetUpperGingiva();
            var upperGingivaNum = upperGingiva.GetMeshVertex();
            var upperGingivaFaceNum = upperGingiva.GetMeshFace();
            var upGingivaVertex = [];
            var upGingivaVertexNorm = [];
            var upGingivaVertexDepth = [];
            var upGingivaFace = [];
            vertexcolors.length = 0;
            // 顶点
            for (var vi = 0; vi < upperGingivaNum; vi++) {
                bGet = upperGingiva.GetVertexXYZ(ptVertex, vi);
                if (bGet) {
                    upGingivaVertex.push(new window.THREE.Vector3(ptVertex.GetX(), ptVertex.GetY(), ptVertex.GetZ()));
                }
                bGet = upperGingiva.GetVertexNorm(ptVertex, vi);
                if (bGet) {
                    upGingivaVertexNorm.push(new window.THREE.Vector3(ptVertex.GetX(), ptVertex.GetY(), ptVertex.GetZ()));
                }
                upGingivaVertexDepth.push(upperGingiva.GetVertexColorDepth(vi));
            }
            GingivaGradient(plUpperBase, upGingivaVertex, upGingivaVertexDepth, vertexcolors);
            // console.log("牙龈点" + upperGingivaNum);
            // 面的顶点索引
            for (var fi = 0; fi < upperGingivaFaceNum; fi++) {
                bGet = upperGingiva.GetFaceIndex(ptFaceIndex, fi);
                if (bGet) {
                    vertexnorm = [];
                    vertexnorm.push(upGingivaVertexNorm[ptFaceIndex.GetX()]);
                    vertexnorm.push(upGingivaVertexNorm[ptFaceIndex.GetY()]);
                    vertexnorm.push(upGingivaVertexNorm[ptFaceIndex.GetZ()]);
                    vertexcolor = [];
                    vertexcolor.push(vertexcolors[ptFaceIndex.GetX()]);
                    vertexcolor.push(vertexcolors[ptFaceIndex.GetY()]);
                    vertexcolor.push(vertexcolors[ptFaceIndex.GetZ()]);
                    upGingivaFace.push(new window.THREE.Face3(ptFaceIndex.GetX(), ptFaceIndex.GetY(), ptFaceIndex.GetZ(), vertexnorm, vertexcolor));
                }
            }
            upperGingiva = null;
            // console.log("牙龈面" + upperGingivaFaceNum);
            if (upperGingivaFaceNum > 0) {
                G_upperGingivaMesh.geometry.dispose();
                geom = new window.THREE.Geometry();
                geom.vertices = upGingivaVertex;
                geom.faces = upGingivaFace;
                G_upperGingivaMesh.geometry = new window.THREE.BufferGeometry().fromGeometry(geom);
                geom.dispose();
                geom = null;
            }
            vertexnorm.length = 0;
            upGingivaVertex.length = 0;
            upGingivaVertexNorm.length = 0;
            upGingivaVertexDepth.length = 0;
            upGingivaFace.length = 0;
            upGingivaVertex = null;
            upGingivaVertexNorm = null;
            upGingivaFace = null;
        }

        // 下颌牙龈
        {
            nGingivaID = i; // 牙龈编号:步骤数+上下颌标识,1为上颌,0为下颌

            var lowerGingiva = mafModel.GetLowerGingiva();
            var lowerGingivaNum = lowerGingiva.GetMeshVertex();
            var lowerGingivaFaceNum = lowerGingiva.GetMeshFace();
            var lowGingivaVertex = [];
            var lowGingivaVertexNorm = [];
            var lowGingivaVertexDepth = [];
            var lowGingivaFace = [];
            vertexcolors.length = 0;
            // 顶点
            for (vi = 0; vi < lowerGingivaNum; vi++) {
                bGet = lowerGingiva.GetVertexXYZ(ptVertex, vi);
                if (bGet) {
                    lowGingivaVertex.push(new window.THREE.Vector3(ptVertex.GetX(), ptVertex.GetY(), ptVertex.GetZ()));
                }
                bGet = lowerGingiva.GetVertexNorm(ptVertex, vi);
                if (bGet) {
                    lowGingivaVertexNorm.push(new window.THREE.Vector3(ptVertex.GetX(), ptVertex.GetY(), ptVertex.GetZ()));
                }
                lowGingivaVertexDepth.push(lowerGingiva.GetVertexColorDepth(vi));
            }
            GingivaGradient(plLowerBase, lowGingivaVertex, lowGingivaVertexDepth, vertexcolors);
            // 面的顶点索引
            for (fi = 0; fi < lowerGingivaFaceNum; fi++) {
                bGet = lowerGingiva.GetFaceIndex(ptFaceIndex, fi);
                if (bGet) {
                    vertexnorm = [];
                    vertexnorm.push(lowGingivaVertexNorm[ptFaceIndex.GetX()]);
                    vertexnorm.push(lowGingivaVertexNorm[ptFaceIndex.GetY()]);
                    vertexnorm.push(lowGingivaVertexNorm[ptFaceIndex.GetZ()]);
                    vertexcolor = [];
                    vertexcolor.push(vertexcolors[ptFaceIndex.GetX()]);
                    vertexcolor.push(vertexcolors[ptFaceIndex.GetY()]);
                    vertexcolor.push(vertexcolors[ptFaceIndex.GetZ()]);
                    lowGingivaFace.push(new window.THREE.Face3(ptFaceIndex.GetX(), ptFaceIndex.GetY(), ptFaceIndex.GetZ(), vertexnorm, vertexcolor));
                }
            }
            lowerGingiva = null;
            if (lowerGingivaFaceNum > 0) {
                G_lowGingivaMesh.geometry.dispose();
                geom = new window.THREE.Geometry();
                geom.vertices = lowGingivaVertex;
                geom.faces = lowGingivaFace;
                G_lowGingivaMesh.geometry = new window.THREE.BufferGeometry().fromGeometry(geom);
                G_lowGingivaMesh.position.set(offsetMA.x, offsetMA.y, offsetMA.z);
                geom.dispose();
                geom = null;
            }
            vertexnorm.length = 0;
            lowGingivaVertex.length = 0;
            lowGingivaVertexNorm.length = 0;
            lowGingivaVertexDepth.length = 0;
            lowGingivaFace.length = 0;
            lowGingivaVertex = null;
            lowGingivaVertexNorm = null;
            lowGingivaFace = null;
        }
    }
}

// 解析MAF文件
function parserMAFData(filename, b7z, parserMaf) {
    if (JSON.stringify(mafModel) == '{}') {
        mafModel = parserMaf.GetModel(); // 获取模型数据集
    }
    nTotalStep = mafModel.GetUpperJawTotalTreatmentStep(); // 总步数
    parserMAData();

    var pShell = {}; // 公用Shell对象
    var i = 0; // 公用计数器
    var bGet = false; // 公用数据是否读取成功标志
    var ptVertex = new window.CVector(); // 公用点对象
    var ptFaceIndex = new window.CVector();

    // 牙龈数据
    var nGingivaID = 0;
    var vertexnorm = [];
    var vertexcolors = [];
    var vertexcolor = [];
    var vertexcurv = [];
    gingivaBuild.BuildGingivaMesh(mafModel);

    // 牙齿和附件数据
    var nShellListSize = mafModel.GetShellListSize();
    var nAttachListSize = mafModel.GetAttachListSize();
    var nCutListSize = mafModel.GetCutListSize();
    var nHatListSize = mafModel.GetHatListSize();
    var nTorqueListSize = mafModel.GetTorqueListSize();
    // 获取牙齿&附件数据集
    // 附件数量
    // 位置信息
    for (i = 0; i < nShellListSize; i++) {
        pShell = mafModel.GetShell(i);
        let nToothID = pShell.GetToothFDI();
        let vecStepOffset = new Array(); // 单个模型的Location数据
        let vecStepQuaternion = new Array(); // 单个模型的Location数据
        let vecStepScale = new Array();
        for (let setpCount = 0; setpCount < nTotalStep; setpCount++) {
            let stepLocation = pShell.GetLocationOfStep(setpCount);
            let stepOffset = stepLocation.GetOffset();
            let stepQuaternion = stepLocation.GetQuaternion();
            let offsetMA = Math.abs(nToothID) < 30 ? new window.THREE.Vector3(0, 0, 0) : mapStepinfo_Offset.get(0)[setpCount];
            vecStepOffset.push(new window.THREE.Vector3(stepOffset.GetX(), stepOffset.GetY(), stepOffset.GetZ()).add(offsetMA));
            vecStepQuaternion.push(new window.THREE.Quaternion(stepQuaternion.GetX(), stepQuaternion.GetY(), stepQuaternion.GetZ(), stepQuaternion.GetW()));
            pShell.GetDentureScale(stepOffset, setpCount);
            vecStepScale.push(new window.THREE.Vector3(stepOffset.GetX(), stepOffset.GetY(), stepOffset.GetZ()));
        }
        mapStepinfo_Offset.set(nToothID, vecStepOffset);
        mapStepinfo_Quaternion.set(nToothID, vecStepQuaternion);
        mapStepinfo_Scale.set(nToothID, vecStepScale);
        pShell = null;
    }
    for (i = 0; i < nAttachListSize; i++) {
        pShell = mafModel.GetAttachment(i);
        let nParentID = pShell.GetParentFDI();
        if (nParentID == -1) continue;
        let nAttachID = (nParentID > 0) ? (nParentID * 1000 + i) : (nParentID * 1000 - i);
        let vecStepOffset = new Array(); // 单个模型的Location数据
        let vecStepQuaternion = new Array(); // 单个模型的Location数据
        for (let setpCount = 0; setpCount < nTotalStep; setpCount++) {
            if (pShell.IsAttachInRange(setpCount)) {
                let stepLocation = pShell.GetAttachLocationOfStep(setpCount);
                let stepOffset = stepLocation.GetOffset();
                let stepQuaternion = stepLocation.GetQuaternion();
                let offsetMA = Math.abs(nParentID) < 30 ? new window.THREE.Vector3(0, 0, 0) : mapStepinfo_Offset.get(0)[setpCount];
                vecStepOffset.push(new window.THREE.Vector3(stepOffset.GetX(), stepOffset.GetY(), stepOffset.GetZ()).add(offsetMA));
                vecStepQuaternion.push(new window.THREE.Quaternion(stepQuaternion.GetX(), stepQuaternion.GetY(), stepQuaternion.GetZ(), stepQuaternion.GetW()));
            } else {
                vecStepOffset.push(new window.THREE.Vector3(0, 0, 0));
                vecStepQuaternion.push(new window.THREE.Quaternion(1, 0, 0, 0));
            }
        }
        mapStepinfo_Offset.set(nAttachID, vecStepOffset);
        mapStepinfo_Quaternion.set(nAttachID, vecStepQuaternion);
        pShell = null;
    }
    for (i = 0; i < nCutListSize; i++) {
        pShell = mafModel.GetCut(i);
        let nParentID = pShell.GetParentFDI();
        if (nParentID == -1) continue;
        let nCutID = (nParentID > 0) ? (nParentID * 100 + i) : (nParentID * 100 - i);
        let vecStepOffset = new Array(); // 单个模型的Location数据
        let vecStepQuaternion = new Array(); // 单个模型的Location数据
        for (let setpCount = 0; setpCount < nTotalStep; setpCount++) {
            if (pShell.IsAttachInRange(setpCount)) {
                let stepLocation = pShell.GetCutLocationOfStep(setpCount);
                let stepOffset = stepLocation.GetOffset();
                let stepQuaternion = stepLocation.GetQuaternion();
                let offsetMA = Math.abs(nParentID) < 30 ? new window.THREE.Vector3(0, 0, 0) : mapStepinfo_Offset.get(0)[setpCount];
                vecStepOffset.push(new window.THREE.Vector3(stepOffset.GetX(), stepOffset.GetY(), stepOffset.GetZ()).add(offsetMA));
                vecStepQuaternion.push(new window.THREE.Quaternion(stepQuaternion.GetX(), stepQuaternion.GetY(), stepQuaternion.GetZ(), stepQuaternion.GetW()));
            } else {
                vecStepOffset.push(new window.THREE.Vector3(0, 0, 0));
                vecStepQuaternion.push(new window.THREE.Quaternion(1, 0, 0, 0));
            }
        }
        mapStepinfo_Offset.set(nCutID, vecStepOffset);
        mapStepinfo_Quaternion.set(nCutID, vecStepQuaternion);
        pShell = null;
    }
    for (i = 0; i < nHatListSize; i++) {
        pShell = mafModel.GetHat(i);
        let nParentID = pShell.GetParentFDI();
        if (nParentID == -1) continue;
        let nHatID = (nParentID > 0) ? (nParentID * 10000 + i) : (nParentID * 10000 - i);
        let vecStepOffset = new Array(); // 单个模型的Location数据
        let vecStepQuaternion = new Array(); // 单个模型的Location数据
        let vecStepScale = new Array();
        for (let setpCount = 0; setpCount < nTotalStep; setpCount++) {
            let stepLocation = pShell.GetHatLocationOfStep(setpCount);
            let stepOffset = stepLocation.GetOffset();
            let stepQuaternion = stepLocation.GetQuaternion();
            let offsetMA = Math.abs(nParentID) < 30 ? new window.THREE.Vector3(0, 0, 0) : mapStepinfo_Offset.get(0)[setpCount];
            vecStepOffset.push(new window.THREE.Vector3(stepOffset.GetX(), stepOffset.GetY(), stepOffset.GetZ()).add(offsetMA));
            vecStepQuaternion.push(new window.THREE.Quaternion(stepQuaternion.GetX(), stepQuaternion.GetY(), stepQuaternion.GetZ(), stepQuaternion.GetW()));
            pShell.GetHatScale(stepOffset, setpCount);
            vecStepScale.push(new window.THREE.Vector3(stepOffset.GetX(), stepOffset.GetY(), stepOffset.GetZ()));
        }
        mapStepinfo_Offset.set(nHatID, vecStepOffset);
        mapStepinfo_Quaternion.set(nHatID, vecStepQuaternion);
        mapStepinfo_Scale.set(nHatID, vecStepScale);
        pShell = null;
    }
    for (i = 0; i < nTorqueListSize; i++) {
        pShell = mafModel.GetTorque(i);
        let nParentID = pShell.GetParentFDI();
        if (nParentID == -1) continue;
        let nTorqueID = (nParentID > 0) ? (nParentID * 1000 + i + nAttachListSize) : (nParentID * 1000 - i - nAttachListSize);
        let vecStepOffset = new Array(); // 单个模型的Location数据
        let vecStepQuaternion = new Array(); // 单个模型的Location数据
        for (let setpCount = 0; setpCount < nTotalStep; setpCount++) {
            if (pShell.IsAttachInRange(setpCount)){
                let stepLocation = pShell.GetCutLocationOfStep(setpCount);
                let stepOffset = stepLocation.GetOffset();
                let stepQuaternion = stepLocation.GetQuaternion();
                let offsetMA = Math.abs(nParentID) < 30 ? new window.THREE.Vector3(0, 0, 0) : mapStepinfo_Offset.get(0)[setpCount];
                vecStepOffset.push(new window.THREE.Vector3(stepOffset.GetX(), stepOffset.GetY(), stepOffset.GetZ()).add(offsetMA));
                vecStepQuaternion.push(new window.THREE.Quaternion(stepQuaternion.GetX(), stepQuaternion.GetY(), stepQuaternion.GetZ(), stepQuaternion.GetW()));
            }else{
                vecStepOffset.push(new window.THREE.Vector3(0,0,0));
                vecStepQuaternion.push(new window.THREE.Quaternion(1,0,0,0));
            }
        }
        mapStepinfo_Offset.set(nTorqueID, vecStepOffset);
        mapStepinfo_Quaternion.set(nTorqueID, vecStepQuaternion);
        pShell = null;
    }
    // 模型数据
    for (i = 0; i < nShellListSize; i++) {
        pShell = mafModel.GetShell(i); // 单个Shell对象数据
        let nToothID = pShell.GetToothFDI();
        let offset = mapStepinfo_Offset.get(nToothID)[0]; // 偏移量
        let quat = mapStepinfo_Quaternion.get(nToothID)[0]; // 四元数
        let sca = mapStepinfo_Scale.get(nToothID)[0];
        let bDenture = IsDentureVacuole(mapStepinfo_Scale.get(nToothID));
        let j = 0; // 临时公用点面计数器
        let nFaceNum = pShell.GetMeshFace();
        let nVertexNum = pShell.GetMeshVertex();
        let toothMesh = {};
        let toothVertex = [];
        let toothVertexNorm = [];
        let toothFace = [];
        vertexcolor.length = 0;
        vertexcolors.length = 0;
        vertexcurv.length = 0;
        for (j = 0; j < nVertexNum; j++) {
            bGet = pShell.GetVertexXYZ(ptVertex, j);
            if (bGet) {
                toothVertex.push(new window.THREE.Vector3(ptVertex.GetX(), ptVertex.GetY(), ptVertex.GetZ()));
            }
            bGet = pShell.GetVertexNorm(ptVertex, j);
            if (bGet) {
                toothVertexNorm.push(new window.THREE.Vector3(ptVertex.GetX(), ptVertex.GetY(), ptVertex.GetZ()));
            }
            let curv = pShell.GetVertexCurv(j);
            vertexcurv.push(curv);
        }
        ToothGradient(toothVertex, toothVertexNorm, vertexcurv, vertexcolors);
        for (j = 0; j < nFaceNum; j++) {
            bGet = pShell.GetFaceIndex(ptFaceIndex, j);
            if (bGet) {
                vertexnorm = [];
                vertexnorm.push(toothVertexNorm[ptFaceIndex.GetX()]);
                vertexnorm.push(toothVertexNorm[ptFaceIndex.GetY()]);
                vertexnorm.push(toothVertexNorm[ptFaceIndex.GetZ()]);
                vertexcolor = [];
                if (bDenture) {
                    vertexcolor.push(new window.THREE.Color(0.63,0.69,0.63));
                    vertexcolor.push(new window.THREE.Color(0.63,0.69,0.63));
                    vertexcolor.push(new window.THREE.Color(0.63,0.69,0.63));
                } else {
                    vertexcolor.push(vertexcolors[ptFaceIndex.GetX()]);
                    vertexcolor.push(vertexcolors[ptFaceIndex.GetY()]);
                    vertexcolor.push(vertexcolors[ptFaceIndex.GetZ()]);
                }
                toothFace.push(new window.THREE.Face3(ptFaceIndex.GetX(), ptFaceIndex.GetY(), ptFaceIndex.GetZ(), vertexnorm, vertexcolor));
            }
        }
        toothMesh = createGeometry(toothVertex, toothFace, toothMaterial, false); // 初始坐标在(0,0,0),四元数为(1.0.0.0)
        toothMesh.position.set(offset.x, offset.y, offset.z); // 设定偏移量
        toothMesh.quaternion.set(quat.x, quat.y, quat.z, quat.w); // 设定四元数旋转量
        toothMesh.scale.set(sca.x, sca.y, sca.z);
        toothMesh.visible = (sca.x != 0 && sca.y != 0 && sca.z != 0);
        scene.add(toothMesh); // 把当前模型置入场景
        vertexnorm.length = 0;
        toothVertex.length = 0;
        toothVertexNorm.length = 0;
        toothFace.length = 0;
        toothVertex = null;
        toothVertexNorm = null;
        toothFace = null;
        vecTeeth.set(nToothID, toothMesh); // 设定模型键值对索引关系
        pShell = null;
    }
    for (i = 0; i < nAttachListSize; i++) {
        pShell = mafModel.GetAttachment(i); // 单个Shell对象数据
        let nParentID = pShell.GetParentFDI();
        if (nParentID == -1) continue;
        let nAttachID = (nParentID > 0) ? (nParentID * 1000 + i) : (nParentID * 1000 - i); // 牙齿ID+容器内序列

        let j = 0; // 临时公用点面计数器
        let nFaceNum = pShell.GetMeshFace();
        let nVertexNum = pShell.GetMeshVertex();
        let toothMesh = {};
        let toothVertex = [];
        let toothVertexNorm = [];
        let toothFace = [];
        for (j = 0; j < nVertexNum; j++) {
            bGet = pShell.GetVertexXYZ(ptVertex, j);
            if (bGet) {
                toothVertex.push(new window.THREE.Vector3(ptVertex.GetX(), ptVertex.GetY(), ptVertex.GetZ()));
            }
            bGet = pShell.GetVertexNorm(ptVertex, j);
            if (bGet) {
                toothVertexNorm.push(new window.THREE.Vector3(ptVertex.GetX(), ptVertex.GetY(), ptVertex.GetZ()));
            }
        }

        for (j = 0; j < nFaceNum; j++) {
            bGet = pShell.GetFaceIndex(ptFaceIndex, j);
            if (bGet) {
                vertexnorm = [];
                vertexnorm.push(toothVertexNorm[ptFaceIndex.GetX()]);
                vertexnorm.push(toothVertexNorm[ptFaceIndex.GetY()]);
                vertexnorm.push(toothVertexNorm[ptFaceIndex.GetZ()]);
                toothFace.push(new window.THREE.Face3(ptFaceIndex.GetX(), ptFaceIndex.GetY(), ptFaceIndex.GetZ(), vertexnorm));
            }
        }
        toothMesh = createGeometry(toothVertex, toothFace, attachMaterial, false); // 初始坐标在(0,0,0),四元数为(1.0.0.0)
        toothMesh.visible = pShell.IsAttachInRange(0);
        if (toothMesh.visible) {
            let offset = mapStepinfo_Offset.get(nAttachID)[0]; // 偏移量
            let quat = mapStepinfo_Quaternion.get(nAttachID)[0]; // 四元数
            toothMesh.position.set(offset.x, offset.y, offset.z); // 设定偏移量
            toothMesh.quaternion.set(quat.x, quat.y, quat.z, quat.w); // 设定四元数旋转量
        }
        scene.add(toothMesh); // 把当前模型置入场景
        vertexnorm.length = 0;
        toothVertex.length = 0;
        toothVertexNorm.length = 0;
        toothFace.length = 0;
        toothVertex = null;
        toothVertexNorm = null;
        toothFace = null;
        vecAttachment.set(nAttachID, toothMesh); // 设定模型键值对索引关系
        pShell = null;
    }
    for (i = 0; i < nCutListSize; i++) {
        pShell = mafModel.GetCut(i); // 单个Shell对象数据
        let nParentID = pShell.GetParentFDI();
        if (nParentID == -1) continue;
        let nCutID = (nParentID > 0) ? (nParentID * 100 + i) : (nParentID * 100 - i); // 牙齿ID+容器内序列

        let j = 0; // 临时公用点面计数器
        let nFaceNum = pShell.GetMeshFace();
        let nVertexNum = pShell.GetMeshVertex();
        let toothMesh = {};
        let toothVertex = [];
        let toothVertexNorm = [];
        let toothFace = [];
        for (j = 0; j < nVertexNum; j++) {
            bGet = pShell.GetVertexXYZ(ptVertex, j);
            if (bGet) {
                toothVertex.push(new window.THREE.Vector3(ptVertex.GetX(), ptVertex.GetY(), ptVertex.GetZ()));
            }
            bGet = pShell.GetVertexNorm(ptVertex, j);
            if (bGet) {
                toothVertexNorm.push(new window.THREE.Vector3(ptVertex.GetX(), ptVertex.GetY(), ptVertex.GetZ()));
            }
        }

        for (j = 0; j < nFaceNum; j++) {
            bGet = pShell.GetFaceIndex(ptFaceIndex, j);
            if (bGet) {
                vertexnorm = [];
                vertexnorm.push(toothVertexNorm[ptFaceIndex.GetX()]);
                vertexnorm.push(toothVertexNorm[ptFaceIndex.GetY()]);
                vertexnorm.push(toothVertexNorm[ptFaceIndex.GetZ()]);
                toothFace.push(new window.THREE.Face3(ptFaceIndex.GetX(), ptFaceIndex.GetY(), ptFaceIndex.GetZ(), vertexnorm));
            }
        }
        toothMesh = createGeometry(toothVertex, toothFace, cutterMaterial, false); // 初始坐标在(0,0,0),四元数为(1.0.0.0)
        toothMesh.visible = pShell.IsAttachInRange(0);
        if (toothMesh.visible) {
            let offset = mapStepinfo_Offset.get(nCutID)[0]; // 偏移量
            let quat = mapStepinfo_Quaternion.get(nCutID)[0]; // 四元数
            toothMesh.position.set(offset.x, offset.y, offset.z); // 设定偏移量
            toothMesh.quaternion.set(quat.x, quat.y, quat.z, quat.w); // 设定四元数旋转量
        }
        scene.add(toothMesh); // 把当前模型置入场景
        vertexnorm.length = 0;
        toothVertex.length = 0;
        toothVertexNorm.length = 0;
        toothFace.length = 0;
        toothVertex = null;
        toothVertexNorm = null;
        toothFace = null;
        vecCut.set(nCutID, toothMesh); // 设定模型键值对索引关系
        pShell = null;
    }
    for (i = 0; i < nHatListSize; i++) {
        pShell = mafModel.GetHat(i); // 单个Shell对象数据
        let nParentID = pShell.GetParentFDI();
        if (nParentID == -1) continue;
        let nHatID = (nParentID > 0) ? (nParentID * 10000 + i) : (nParentID * 10000 - i);
        let offset = mapStepinfo_Offset.get(nHatID)[0]; // 偏移量
        let quat = mapStepinfo_Quaternion.get(nHatID)[0]; // 四元数
        let sca = mapStepinfo_Scale.get(nHatID)[0];
        let j = 0; // 临时公用点面计数器
        let nFaceNum = pShell.GetMeshFace();
        let nVertexNum = pShell.GetMeshVertex();
        let toothMesh = {};
        let toothVertex = [];
        let toothVertexNorm = [];
        let toothFace = [];
        for (j = 0; j < nVertexNum; j++) {
            bGet = pShell.GetVertexXYZ(ptVertex, j);
            if (bGet) {
                toothVertex.push(new window.THREE.Vector3(ptVertex.GetX(), ptVertex.GetY(), ptVertex.GetZ()));
            }
            bGet = pShell.GetVertexNorm(ptVertex, j);
            if (bGet) {
                toothVertexNorm.push(new window.THREE.Vector3(ptVertex.GetX(), ptVertex.GetY(), ptVertex.GetZ()));
            }
        }

        for (j = 0; j < nFaceNum; j++) {
            bGet = pShell.GetFaceIndex(ptFaceIndex, j);
            if (bGet) {
                vertexnorm = [];
                vertexnorm.push(toothVertexNorm[ptFaceIndex.GetX()]);
                vertexnorm.push(toothVertexNorm[ptFaceIndex.GetY()]);
                vertexnorm.push(toothVertexNorm[ptFaceIndex.GetZ()]);
                toothFace.push(new window.THREE.Face3(ptFaceIndex.GetX(), ptFaceIndex.GetY(), ptFaceIndex.GetZ(), vertexnorm, vertexcolor));
            }
        }
        toothMesh = createGeometry(toothVertex, toothFace, MAEquipMaterial, false); // 初始坐标在(0,0,0),四元数为(1.0.0.0)
        toothMesh.position.set(offset.x, offset.y, offset.z); // 设定偏移量
        toothMesh.quaternion.set(quat.x, quat.y, quat.z, quat.w); // 设定四元数旋转量
        toothMesh.scale.set(sca.x, sca.y, sca.z);
        scene.add(toothMesh); // 把当前模型置入场景
        vertexnorm.length = 0;
        toothVertex.length = 0;
        toothVertexNorm.length = 0;
        toothFace.length = 0;
        toothVertex = null;
        toothVertexNorm = null;
        toothFace = null;
        vecHat.set(nHatID, toothMesh); // 设定模型键值对索引关系
        pShell = null;
    }
    for (i = 0; i < nTorqueListSize; i++) {
        pShell = mafModel.GetTorque(i); // 单个Shell对象数据
        let nParentID = pShell.GetParentFDI();
        if (nParentID == -1) continue;
        let nTorqueID = (nParentID > 0) ? (nParentID * 1000 + i + nAttachListSize) : (nParentID * 1000 - i - nAttachListSize); // 牙齿ID+容器内序列

        let j = 0; // 临时公用点面计数器
        let nFaceNum = pShell.GetMeshFace();
        let nVertexNum = pShell.GetMeshVertex();
        let toothMesh = {};
        let toothVertex = [];
        let toothVertexNorm = [];
        let toothFace = [];
        for (j = 0; j < nVertexNum; j++) {
            bGet = pShell.GetVertexXYZ(ptVertex, j);
            if (bGet) {
                toothVertex.push(new window.THREE.Vector3(ptVertex.GetX(), ptVertex.GetY(), ptVertex.GetZ()));
            }
            bGet = pShell.GetVertexNorm(ptVertex, j);
            if (bGet) {
                toothVertexNorm.push(new window.THREE.Vector3(ptVertex.GetX(), ptVertex.GetY(), ptVertex.GetZ()));
            }
        }

        for (j = 0; j < nFaceNum; j++) {
            bGet = pShell.GetFaceIndex(ptFaceIndex, j);
            if (bGet) {
                vertexnorm = [];
                vertexnorm.push(toothVertexNorm[ptFaceIndex.GetX()]);
                vertexnorm.push(toothVertexNorm[ptFaceIndex.GetY()]);
                vertexnorm.push(toothVertexNorm[ptFaceIndex.GetZ()]);
                toothFace.push(new window.THREE.Face3(ptFaceIndex.GetX(), ptFaceIndex.GetY(), ptFaceIndex.GetZ(), vertexnorm));
            }
        }
        toothMesh = createGeometry(toothVertex, toothFace, cutterMaterial, false); // 初始坐标在(0,0,0),四元数为(1.0.0.0)
        toothMesh.visible = pShell.IsAttachInRange(0);
        if (toothMesh.visible){
            let offset = mapStepinfo_Offset.get(nTorqueID)[0]; // 偏移量
            let quat = mapStepinfo_Quaternion.get(nTorqueID)[0]; // 四元数
            toothMesh.position.set(offset.x, offset.y, offset.z); // 设定偏移量
            toothMesh.quaternion.set(quat.x, quat.y, quat.z, quat.w); // 设定四元数旋转量
        }
        scene.add(toothMesh); // 把当前模型置入场景
        vertexnorm.length = 0;
        toothVertex.length = 0;
        toothVertexNorm.length = 0;
        toothFace.length = 0;
        toothVertex = null;
        toothVertexNorm = null;
        toothFace = null;
        vecTorque.set(nTorqueID, toothMesh); // 设定模型键值对索引关系
        pShell = null;
    }
    // 公用牙龈编号
    for (i = 0; i < 1; i++) { // nTotalStep  1
        gingivaBuild.SetGingivaMeshOfStep(mafModel, i);
        let offsetMA = mapStepinfo_Offset.get(0)[i];
        var plUpperBase = new window.THREE.Plane(),
            plLowerBase = new window.THREE.Plane();
        // 上颌牙龈底座
        {
            nGingivaID = i; // 牙龈编号:步骤数+上下颌标识,1为上颌,0为下颌

            var upperGingiva_Base = mafModel.GetUpperGingiva();
            var upperGingivaNum_Base = upperGingiva_Base.GetBaseMeshVertex();
            var upperGingivaFaceNum_Base = upperGingiva_Base.GetBaseMeshFace();
            var upGingivaVertex_Base = [];
            var upGingivaVertexNorm_Base = [];
            var upGingivaFace_Base = [];
            // 顶点
            for (vi = 0; vi < upperGingivaNum_Base; vi++) {
                bGet = upperGingiva_Base.GetBaseVertexXYZ(ptVertex, vi);
                if (bGet) {
                    upGingivaVertex_Base.push(new window.THREE.Vector3(ptVertex.GetX(), ptVertex.GetY(), ptVertex.GetZ()));
                }
                bGet = upperGingiva_Base.GetBaseVertexNorm(ptVertex, vi);
                if (bGet) {
                    upGingivaVertexNorm_Base.push(new window.THREE.Vector3(ptVertex.GetX(), ptVertex.GetY(), ptVertex.GetZ()));
                }
            }
            // console.log("牙龈点" + upperGingivaNum);
            // 面的顶点索引
            for (fi = 0; fi < upperGingivaFaceNum_Base; fi++) {
                bGet = upperGingiva_Base.GetBaseFaceIndex(ptFaceIndex, fi);
                if (bGet) {
                    vertexnorm = [];
                    vertexnorm.push(upGingivaVertexNorm_Base[ptFaceIndex.GetX()]);
                    vertexnorm.push(upGingivaVertexNorm_Base[ptFaceIndex.GetY()]);
                    vertexnorm.push(upGingivaVertexNorm_Base[ptFaceIndex.GetZ()]);
                    upGingivaFace_Base.push(new window.THREE.Face3(ptFaceIndex.GetX(), ptFaceIndex.GetY(), ptFaceIndex.GetZ(), vertexnorm));
                }
            }
            // console.log("牙龈面" + upperGingivaFaceNum);
            upperGingiva_Base = null;
            if (upperGingivaFaceNum_Base > 0) {
                plUpperBase.setFromCoplanarPoints(upGingivaVertex_Base[ptFaceIndex.GetX()], upGingivaVertex_Base[ptFaceIndex.GetY()], upGingivaVertex_Base[ptFaceIndex.GetZ()]);
                G_upperGingivaMesh_Base = createGeometry(upGingivaVertex_Base, upGingivaFace_Base, gingivabaseMaterial, false);
                scene.add(G_upperGingivaMesh_Base);
            }
            vertexnorm.length = 0;
            upGingivaVertex_Base.length = 0;
            upGingivaVertexNorm_Base.length = 0;
            upGingivaFace_Base.length = 0;
            upGingivaVertex_Base = null;
            upGingivaVertexNorm_Base = null;
            upGingivaFace_Base = null;
        }

        // 下颌牙龈底座
        {
            nGingivaID = i; // 牙龈编号:步骤数+上下颌标识,1为上颌,0为下颌

            var lowerGingiva_Base = mafModel.GetLowerGingiva();
            var lowerGingivaNum_Base = lowerGingiva_Base.GetBaseMeshVertex();
            var lowerGingivaFaceNum_Base = lowerGingiva_Base.GetBaseMeshFace();
            var lowGingivaVertex_Base = [];
            var lowGingivaVertexNorm_Base = [];
            var lowGingivaFace_Base = [];
            // 顶点
            for (vi = 0; vi < lowerGingivaNum_Base; vi++) {
                bGet = lowerGingiva_Base.GetBaseVertexXYZ(ptVertex, vi);
                if (bGet) {
                    lowGingivaVertex_Base.push(new window.THREE.Vector3(ptVertex.GetX(), ptVertex.GetY(), ptVertex.GetZ()));
                }
                bGet = lowerGingiva_Base.GetBaseVertexNorm(ptVertex, vi);
                if (bGet) {
                    lowGingivaVertexNorm_Base.push(new window.THREE.Vector3(ptVertex.GetX(), ptVertex.GetY(), ptVertex.GetZ()));
                }
            }

            // 面的顶点索引
            for (fi = 0; fi < lowerGingivaFaceNum_Base; fi++) {
                bGet = lowerGingiva_Base.GetBaseFaceIndex(ptFaceIndex, fi);
                if (bGet) {
                    vertexnorm = [];
                    vertexnorm.push(lowGingivaVertexNorm_Base[ptFaceIndex.GetX()]);
                    vertexnorm.push(lowGingivaVertexNorm_Base[ptFaceIndex.GetY()]);
                    vertexnorm.push(lowGingivaVertexNorm_Base[ptFaceIndex.GetZ()]);
                    lowGingivaFace_Base.push(new window.THREE.Face3(ptFaceIndex.GetX(), ptFaceIndex.GetY(), ptFaceIndex.GetZ(), vertexnorm));
                }
            }
            lowerGingiva_Base = null;
            if (lowerGingivaFaceNum_Base > 0) {
                plLowerBase.setFromCoplanarPoints(lowGingivaVertex_Base[ptFaceIndex.GetX()], lowGingivaVertex_Base[ptFaceIndex.GetY()], lowGingivaVertex_Base[ptFaceIndex.GetZ()]);
                G_lowGingivaMesh_Base = createGeometry(lowGingivaVertex_Base, lowGingivaFace_Base, gingivabaseMaterial, false);
                G_lowGingivaMesh_Base.position.set(offsetMA.x, offsetMA.y, offsetMA.z);
                scene.add(G_lowGingivaMesh_Base);
            }
            vertexnorm.length = 0;
            lowGingivaVertex_Base.length = 0;
            lowGingivaVertexNorm_Base.length = 0;
            lowGingivaFace_Base.length = 0;
            lowGingivaVertex_Base = null;
            lowGingivaVertexNorm_Base = null;
            lowGingivaFace_Base = null;
        }

        // 上颌牙龈
        {
            nGingivaID = i; // 牙龈编号:步骤数+上下颌标识,1为上颌,0为下颌

            var upperGingiva = mafModel.GetUpperGingiva();
            var upperGingivaNum = upperGingiva.GetMeshVertex();
            var upperGingivaFaceNum = upperGingiva.GetMeshFace();
            var upGingivaVertex = [];
            var upGingivaVertexNorm = [];
            var upGingivaVertexDepth = [];
            var upGingivaFace = [];
            vertexcolors.length = 0;
            // 顶点
            for (var vi = 0; vi < upperGingivaNum; vi++) {
                bGet = upperGingiva.GetVertexXYZ(ptVertex, vi);
                if (bGet) {
                    upGingivaVertex.push(new window.THREE.Vector3(ptVertex.GetX(), ptVertex.GetY(), ptVertex.GetZ()));
                }
                bGet = upperGingiva.GetVertexNorm(ptVertex, vi);
                if (bGet) {
                    upGingivaVertexNorm.push(new window.THREE.Vector3(ptVertex.GetX(), ptVertex.GetY(), ptVertex.GetZ()));
                }
                upGingivaVertexDepth.push(upperGingiva.GetVertexColorDepth(vi));
            }
            GingivaGradient(plUpperBase, upGingivaVertex, upGingivaVertexDepth, vertexcolors);
            // console.log("牙龈点" + upperGingivaNum);
            // 面的顶点索引
            for (var fi = 0; fi < upperGingivaFaceNum; fi++) {
                bGet = upperGingiva.GetFaceIndex(ptFaceIndex, fi);
                if (bGet) {
                    vertexnorm = [];
                    vertexnorm.push(upGingivaVertexNorm[ptFaceIndex.GetX()]);
                    vertexnorm.push(upGingivaVertexNorm[ptFaceIndex.GetY()]);
                    vertexnorm.push(upGingivaVertexNorm[ptFaceIndex.GetZ()]);
                    vertexcolor = [];
                    vertexcolor.push(vertexcolors[ptFaceIndex.GetX()]);
                    vertexcolor.push(vertexcolors[ptFaceIndex.GetY()]);
                    vertexcolor.push(vertexcolors[ptFaceIndex.GetZ()]);
                    upGingivaFace.push(new window.THREE.Face3(ptFaceIndex.GetX(), ptFaceIndex.GetY(), ptFaceIndex.GetZ(), vertexnorm, vertexcolor));
                }
            }
            // console.log("牙龈面" + upperGingivaFaceNum);
            upperGingiva = null;
            if (upperGingivaFaceNum > 0) {
                G_upperGingivaMesh = createGeometry(upGingivaVertex, upGingivaFace, gingivaMaterial, true);
                scene.add(G_upperGingivaMesh);
            }
            vertexnorm.length = 0;
            upGingivaVertex.length = 0;
            upGingivaFace.length = 0;
            upGingivaVertexNorm.length = 0;
            upGingivaVertexDepth.length = 0;
            upGingivaVertex = null;
            upGingivaVertexNorm = null;
            upGingivaFace = null;
        }

        // 下颌牙龈
        {
            nGingivaID = i; // 牙龈编号:步骤数+上下颌标识,1为上颌,0为下颌

            var lowerGingiva = mafModel.GetLowerGingiva();
            var lowerGingivaNum = lowerGingiva.GetMeshVertex();
            var lowerGingivaFaceNum = lowerGingiva.GetMeshFace();
            var lowGingivaVertex = [];
            var lowGingivaVertexNorm = [];
            var lowGingivaVertexDepth = [];
            var lowGingivaFace = [];
            vertexcolors.length = 0;
            // 顶点
            for (vi = 0; vi < lowerGingivaNum; vi++) {
                bGet = lowerGingiva.GetVertexXYZ(ptVertex, vi);
                if (bGet) {
                    lowGingivaVertex.push(new window.THREE.Vector3(ptVertex.GetX(), ptVertex.GetY(), ptVertex.GetZ()));
                }
                bGet = lowerGingiva.GetVertexNorm(ptVertex, vi);
                if (bGet) {
                    lowGingivaVertexNorm.push(new window.THREE.Vector3(ptVertex.GetX(), ptVertex.GetY(), ptVertex.GetZ()));
                }
                lowGingivaVertexDepth.push(lowerGingiva.GetVertexColorDepth(vi));
            }
            GingivaGradient(plLowerBase, lowGingivaVertex, lowGingivaVertexDepth, vertexcolors);
            // 面的顶点索引
            for (fi = 0; fi < lowerGingivaFaceNum; fi++) {
                bGet = lowerGingiva.GetFaceIndex(ptFaceIndex, fi);
                if (bGet) {
                    vertexnorm = [];
                    vertexnorm.push(lowGingivaVertexNorm[ptFaceIndex.GetX()]);
                    vertexnorm.push(lowGingivaVertexNorm[ptFaceIndex.GetY()]);
                    vertexnorm.push(lowGingivaVertexNorm[ptFaceIndex.GetZ()]);
                    vertexcolor = [];
                    vertexcolor.push(vertexcolors[ptFaceIndex.GetX()]);
                    vertexcolor.push(vertexcolors[ptFaceIndex.GetY()]);
                    vertexcolor.push(vertexcolors[ptFaceIndex.GetZ()]);
                    lowGingivaFace.push(new window.THREE.Face3(ptFaceIndex.GetX(), ptFaceIndex.GetY(), ptFaceIndex.GetZ(), vertexnorm, vertexcolor));
                }
            }
            lowerGingiva = null;
            if (lowerGingivaFaceNum > 0) {
                G_lowGingivaMesh = createGeometry(lowGingivaVertex, lowGingivaFace, gingivaMaterial, true);
                G_lowGingivaMesh.position.set(offsetMA.x, offsetMA.y, offsetMA.z);
                scene.add(G_lowGingivaMesh);
            }
            vertexnorm.length = 0;
            lowGingivaVertex.length = 0;
            lowGingivaVertexNorm.length = 0;
            lowGingivaVertexDepth.length = 0;
            lowGingivaFace.length = 0;
            lowGingivaVertex = null;
            lowGingivaVertexNorm = null;
            lowGingivaFace = null;
        }
    }

    // var vBoxCenter = new window.CVector();
    // vBoxCenter = mafModel.GetModelBoxCenter();
    // ptBoxCenter.x = vBoxCenter.GetX();
    // ptBoxCenter.y = vBoxCenter.GetY();
    // ptBoxCenter.z = vBoxCenter.GetZ();

    // controls.position0.x = vBoxCenter.GetX();
    // controls.position0.y = vBoxCenter.GetY() - 10;
    // controls.position0.z = vBoxCenter.GetZ();

    // controls.target0.set(ptBoxCenter.x, ptBoxCenter.y, ptBoxCenter.z);
    // controls.up0.set(0, 0, 1); // set a new up vector
    // controls.reset();

    controls.staticMoving = true;
    // 关闭惯性
    stepBar = [];
    // 步数传出
    pShell = mafModel.GetShell(0);
    for (i = 0; i < nTotalStep; i++) {
        let type = [];
        if (pShell.IsStepNOProduction(i)) {
            type.push(1);
        } else {
            type.push(0);
        }
        stepBar.push(type);
    }
    pShell = mafModel.GetShell(nShellListSize - 1);
    for (i = 0; i < nTotalStep; i++) {
        if (pShell.IsStepNOProduction(i)) {
            stepBar[i].push(1);
        } else {
            stepBar[i].push(0);
        }
    }
    pShell = null;
    console.log("方案步数" + i);
    localStorage.setItem(caseno, JSON.stringify(stepBar));

    pointModel(3);
    // console.log("ParserEnd"+getTime());
}

// MA
function parserMAData() {
    let vecMAOffset = new Array();
    for (let setpCount = 0; setpCount < nTotalStep; setpCount++) {
        let stepOffset = parserMaf.GetMAOffset(setpCount);
        vecMAOffset.push(new window.THREE.Vector3(stepOffset.GetX(), stepOffset.GetY(), stepOffset.GetZ()));
    }
    mapStepinfo_Offset.set(0, vecMAOffset);

    let jaw = [true, true, false, false],
        side = [false, true, true, false];
    let MAMesh = {},
        MAVertex = [],
        MAVertexNorm = [],
        MAFace = [],
        ptVertex = new window.CVector(),
        ptFaceIndex = new window.CVector();
    for (let i = 0; i < 4; i++) {
        let vecStepOffset = new Array();
        let vecStepQuaternion = new Array();
        for (let setpCount = 0; setpCount < nTotalStep; setpCount++) {
            let stepLocation = parserMaf.GetMALocation(setpCount, jaw[i], side[i]);
            let stepOffset = stepLocation.GetOffset();
            let stepQuaternion = stepLocation.GetQuaternion();
            vecStepOffset.push(new window.THREE.Vector3(stepOffset.GetX(), stepOffset.GetY(), stepOffset.GetZ()));
            vecStepQuaternion.push(new window.THREE.Quaternion(stepQuaternion.GetX(), stepQuaternion.GetY(), stepQuaternion.GetZ(), stepQuaternion.GetW()));
        }
        mapStepinfo_Offset.set(i + 1, vecStepOffset);
        mapStepinfo_Quaternion.set(i + 1, vecStepQuaternion);

        let nFaceNum = parserMaf.GetMAMeshFace(jaw[i]),
            nVertexNum = parserMaf.GetMAMeshVertex(jaw[i]);
        for (let j = 0; j < nVertexNum; j++) {
            if (parserMaf.GetMAVertexXYZ(ptVertex, j, jaw[i])) {
                MAVertex.push(new window.THREE.Vector3(ptVertex.GetX(), ptVertex.GetY(), ptVertex.GetZ()));
            }
            if (parserMaf.GetMAVertexNorm(ptVertex, j, jaw[i])) {
                MAVertexNorm.push(new window.THREE.Vector3(ptVertex.GetX(), ptVertex.GetY(), ptVertex.GetZ()));
            }
        }
        for (let j = 0; j < nFaceNum; j++) {
            if (parserMaf.GetMAFaceIndex(ptFaceIndex, j, jaw[i])) {
                let vertexnorm = [];
                vertexnorm.push(MAVertexNorm[ptFaceIndex.GetX()]);
                vertexnorm.push(MAVertexNorm[ptFaceIndex.GetY()]);
                vertexnorm.push(MAVertexNorm[ptFaceIndex.GetZ()]);
                MAFace.push(new window.THREE.Face3(ptFaceIndex.GetX(), ptFaceIndex.GetY(), ptFaceIndex.GetZ(), vertexnorm));
            }
        }
        MAMesh = createGeometry(MAVertex, MAFace, MAEquipMaterial, false);
        MAMesh.visible = vecStepOffset[0].lengthSq() > 1.0 ? true : false;
        if (MAMesh.visible) {
            let offset = vecStepOffset[0]; // 偏移量
            let quat = vecStepQuaternion[0]; // 四元数
            MAMesh.position.set(offset.x, offset.y, offset.z); // 设定偏移量
            MAMesh.quaternion.set(quat.x, quat.y, quat.z, quat.w); // 设定四元数旋转量
        }
        scene.add(MAMesh);
        vecMAEquip.set(i + 1, MAMesh);
        MAVertex.length = 0;
        MAVertexNorm.length = 0;
        MAFace.length = 0;
    }
}

function createGeometry(cm_vert, cm_face, material, bSubdivide) {
    var geom = new window.THREE.Geometry();
    // 将顶点和面赋给几何体
    geom.vertices = cm_vert;
    geom.faces = cm_face;
    // 计算每个面的法向量
    // geom.computeFaceNormals();
    // geom.computeVertexNormals();

    var buffergeom = new window.THREE.BufferGeometry().fromGeometry(geom);
    geom.dispose();
    geom = null;
    // console.log("细分前"+geom.faces.length);
    var mesh = new window.THREE.Mesh(buffergeom, material);
    //if (bSubdivide == true) {
    // subdivide(mesh,1);
    // mesh.geometry.computeFaceNormals();
    // mesh.geometry.computeVertexNormals();
    // mesh.material.shading = window.THREE.SmoothShading;
    // mesh.material.shading = window.THREE.FlatShading;
    //}
    mesh.castShadow = true;
    mesh.receiveShadow = true;
    mesh.updateMatrix();
    return mesh;
}

function playTeeth(step) {
    vecTeeth.forEach(function (value, key) {
        var offset = mapStepinfo_Offset.get(key)[step]; // 偏移量
        var quat = mapStepinfo_Quaternion.get(key)[step]; // 四元数
        var sca = mapStepinfo_Scale.get(key)[step];
        value.position.set(offset.x, offset.y, offset.z); // 设定偏移量
        value.quaternion.set(quat.x, quat.y, quat.z, quat.w); // 设定四元数旋转量
        value.scale.set(sca.x, sca.y, sca.z); // 设定偏移量
        if (sca.x == 0 || sca.y == 0 || sca.z == 0) {
            value.visible = false;
        } else if (Math.abs(key) > 10 && Math.abs(key) < 30 && (jawDisplayState === 1 || jawDisplayState === 2)) {
            if (sca.x != 1 || sca.y != 1 || sca.z != 1) value.visible = true;
        } else if (Math.abs(key) > 30 && Math.abs(key) < 50 && (jawDisplayState === -1 || jawDisplayState === 2)) {
            if (sca.x != 1 || sca.y != 1 || sca.z != 1) value.visible = true;
        }
        else {
            value.visible = false;
        }
    });
    vecHat.forEach(function (value, key) {
        var offset = mapStepinfo_Offset.get(key)[step]; // 偏移量
        var quat = mapStepinfo_Quaternion.get(key)[step]; // 四元数
        var sca = mapStepinfo_Scale.get(key)[step];
        value.position.set(offset.x, offset.y, offset.z); // 设定偏移量
        value.quaternion.set(quat.x, quat.y, quat.z, quat.w); // 设定四元数旋转量
        value.scale.set(sca.x, sca.y, sca.z); // 设定偏移量
        value.visible = false;
        if (sca.x == 0 || sca.y == 0 || sca.z == 0) {
            
        } else if (Math.abs(key) > 100000 && Math.abs(key) < 300000 && (jawDisplayState === 1 || jawDisplayState === 2)) {
            value.visible = true;
        } else if (Math.abs(key) > 300000 && Math.abs(key) < 500000 && (jawDisplayState === -1 || jawDisplayState === 2)) {
            value.visible = true;
        }
    });
    // 牙根
    vecTeeth_Root.forEach(function (value, key) {
        var offset = mapStepinfo_Offset.get(key)[step]; // 偏移量
        var quat = mapStepinfo_Quaternion.get(key)[step]; // 四元数
        var sca = mapStepinfo_Scale.get(key)[step];
        vecTeeth_Root.get(key).position.set(offset.x, offset.y, offset.z); // 设定偏移量
        vecTeeth_Root.get(key).quaternion.set(quat.x, quat.y, quat.z, quat.w); // 设定四元数旋转量
        vecTeeth_Root.get(key).scale.set(sca.x, sca.y, sca.z); // 设定偏移量
        if (sca.x == 0 || sca.y == 0 || sca.z == 0) {
            value.visible = false;
        } else if (Math.abs(key) > 10 && Math.abs(key) < 30 && (jawDisplayState === 1 || jawDisplayState === 2)) {

        } else if (Math.abs(key) > 30 && Math.abs(key) < 50 && (jawDisplayState === -1 || jawDisplayState === 2)) {

        } else {
            value.visible = false;
        }
    });
}
// 更新附件的位置信息
function playAttachment(step) {
    vecAttachment.forEach(function (value, key) {
        var offset = mapStepinfo_Offset.get(key)[step]; // 偏移量
        var quat = mapStepinfo_Quaternion.get(key)[step]; // 四元数
        if (offset.x == 0 && offset.y == 0 && offset.z == 0) { // 若在这一步不应该加上此附件
            value.visible = false; // 不显示
        } else {
            value.position.set(offset.x, offset.y, offset.z); // 设定偏移量
            value.quaternion.set(quat.x, quat.y, quat.z, quat.w); // 设定四元数旋转量
            var nParentID = Math.abs(key) / 1000;
            if (nParentID > 10 && nParentID < 30 && (jawDisplayState === 1 || jawDisplayState === 2)) {
                value.visible = true;
            } else if (nParentID > 30 && nParentID < 50 && (jawDisplayState === -1 || jawDisplayState === 2)) {
                value.visible = true;
            } else {
                value.visible = false;
            }
        }
    });
    vecTorque.forEach(function (value, key) {
        var offset = mapStepinfo_Offset.get(key)[step]; // 偏移量
        var quat = mapStepinfo_Quaternion.get(key)[step]; // 四元数
        if (offset.x == 0 && offset.y == 0 && offset.z == 0) { // 若在这一步不应该加上此附件
            value.visible = false; // 不显示
        } else {
            value.position.set(offset.x, offset.y, offset.z); // 设定偏移量
            value.quaternion.set(quat.x, quat.y, quat.z, quat.w); // 设定四元数旋转量
            var nParentID = Math.abs(key) / 1000;
            if (nParentID > 10 && nParentID < 30 && (jawDisplayState === 1 || jawDisplayState === 2)) {
                value.visible = true;
            } else if (nParentID > 30 && nParentID < 50 && (jawDisplayState === -1 || jawDisplayState === 2)) {
                value.visible = true;
            } else {
                value.visible = false;
            }
        }
    });
    vecCut.forEach(function (value, key) {
        var offset = mapStepinfo_Offset.get(key)[step]; // 偏移量
        var quat = mapStepinfo_Quaternion.get(key)[step]; // 四元数
        if (offset.x == 0 && offset.y == 0 && offset.z == 0) { // 若在这一步不应该加上此附件
            value.visible = false; // 不显示
        } else {
            value.position.set(offset.x, offset.y, offset.z); // 设定偏移量
            value.quaternion.set(quat.x, quat.y, quat.z, quat.w); // 设定四元数旋转量
            var nParentID = Math.abs(key) / 100;
            if (nParentID > 10 && nParentID < 30 && (jawDisplayState === 1 || jawDisplayState === 2)) {
                value.visible = true;
            } else if (nParentID > 30 && nParentID < 50 && (jawDisplayState === -1 || jawDisplayState === 2)) {
                value.visible = true;
            } else {
                value.visible = false;
            }
        }
    });
};
// 更新附件的位置信息
function playMAEquipment(step) {
    vecMAEquip.forEach(function (value, key) {
        var offset = mapStepinfo_Offset.get(key)[step]; // 偏移量
        var quat = mapStepinfo_Quaternion.get(key)[step]; // 四元数
        if (offset.lengthSq() < 1.0) {
            value.visible = false; // 不显示
        } else {
            value.position.set(offset.x, offset.y, offset.z); // 设定偏移量
            value.quaternion.set(quat.x, quat.y, quat.z, quat.w); // 设定四元数旋转量
            if (key < 3 && (jawDisplayState === 1 || jawDisplayState === 2)) {
                value.visible = true;
            } else if (key > 2 && (jawDisplayState === -1 || jawDisplayState === 2)) {
                value.visible = true;
            } else {
                value.visible = false;
            }
        }
    });
};
// 显示双颌模型
function showDoubleJaw() {
    showMeshSet(vecTeeth, true);
    showMeshSet(vecTeeth_Root, true);
    showMeshSet(vecHat, true);
    showMeshSet(vecAttachment, true);
    showMeshSet(vecTorque, true);
    showMeshSet(vecCut, true);
    shiftGingivaVisible(stepIndex, 2);
    playAttachment(stepIndex);
    playMAEquipment(stepIndex);
};
// 显示单颌模型
function showSingleJaw(bUpper) {
    if (bUpper === true) {
        showShell(vecTeeth, true);
        showShell(vecTeeth_Root, true);
        showShell(vecHat, true);
        showShell(vecAttachment, true);
        showShell(vecTorque, true);
        showShell(vecCut, true);
        showShell(vecMAEquip, true);
        shiftGingivaVisible(stepIndex, 1);
    } else {
        showShell(vecTeeth, false);
        showShell(vecTeeth_Root, false);
        showShell(vecHat, false);
        showShell(vecAttachment, false);
        showShell(vecTorque, false);
        showShell(vecCut, false);
        showShell(vecMAEquip, false);
        shiftGingivaVisible(stepIndex, -1);
    }
    playAttachment(stepIndex);
    playMAEquipment(stepIndex);
};
// 令模型容器内所有元素显示/隐藏
function showMeshSet(vecMesh, bVisible) {
    vecMesh.forEach(function (value, key) {
        value.visible = bVisible;
    });
};
// 令模型容器内所有上颌/下颌元素显示/隐藏
function showShell(vecMesh, bUpper) {
    vecMesh.forEach(function (value, key) {
        if (Math.abs(key) > 1000) { // 是附件or切割
            var nQuad = parseInt(Math.abs(key).toExponential());
            if (nQuad > 0 && nQuad < 3) { // 上颌
                if (bUpper) {
                    value.visible = true;
                } else {
                    value.visible = false;
                }
            } else if (nQuad > 2 && nQuad < 5) { // 下颌
                if (bUpper === false) {
                    value.visible = true;
                } else {
                    value.visible = false;
                }
            }
        } else if (Math.abs(key) > 10) { // 牙齿
            var nToothID = Math.abs(key);
            if (nToothID > 10 && nToothID < 30) { // 上颌
                if (bUpper && value.scale.lengthSq() != 0) {
                    value.visible = true;
                } else {
                    value.visible = false;
                }
            } else if (nToothID > 30 && nToothID < 50) { // 下颌
                if (bUpper === false && value.scale.lengthSq() != 0) {
                    value.visible = true;
                } else {
                    value.visible = false;
                }
            }
        } else {
            var nQuad = key;
            if (nQuad < 3) { // 上颌
                if (bUpper) {
                    value.visible = true;
                } else {
                    value.visible = false;
                }
            } else if (nQuad < 5) { // 下颌
                if (bUpper === false) {
                    value.visible = true;
                } else {
                    value.visible = false;
                }
            }
        }
    });
};

function showOtherMesh(type) {
    let bUpper = (type === 0 || type === 1) ? true : false,
        bLower = (type === 0 || type === -1) ? true : false;
    if (scene != undefined) {
        scene.children.forEach(function (obj) {
            if (obj.modelType) {
                if (obj.modelType.slice(0, 5) == "upper") {
                    obj.visible = bUpper;
                } else if (obj.modelType.slice(0, 5) == "lower") {
                    obj.visible = bLower;
                }
            }
        });
    }
};
// 新版本切换第n步牙龈的显示
function shiftGingivaVisible(step, nJawState) {

    parseGingivaData(parserMaf, step);

    if ((nJawState === 1 || nJawState === 2)) {
        G_lowGingivaMesh_Base.visible = true;
    } else {
        G_lowGingivaMesh_Base.visible = false;
    }

    if ((nJawState === 1 || nJawState === 2)) {
        G_upperGingivaMesh_Base.visible = true;
    } else {
        G_upperGingivaMesh_Base.visible = false;
    }

    if ((nJawState === 1 || nJawState === 2)) {
        G_lowGingivaMesh.visible = true;
    } else {
        G_lowGingivaMesh.visible = false;
    }

    if ((nJawState === 1 || nJawState === 2)) {
        G_upperGingivaMesh.visible = true;
    } else {
        G_upperGingivaMesh.visible = false;
    }

    if (jawDisplayState === 1) {
        G_lowGingivaMesh.visible = false;
        G_lowGingivaMesh_Base.visible = false;

        G_upperGingivaMesh.visible = true;
        G_upperGingivaMesh_Base.visible = true;
    }

    if (jawDisplayState === -1) {
        G_upperGingivaMesh.visible = false;
        G_upperGingivaMesh_Base.visible = false;

        G_lowGingivaMesh.visible = true;
        G_lowGingivaMesh_Base.visible = true;
    }

};

/********************************** Render ************************************/

function Free() {
    cancelAnimationFrame(animate);
    parserMaf = null;
    mafModel = null;
    vecTeeth = null;
    vecTeeth_Root = null;
    vecAttachment = null;
    vecCut = null;
    vecHat = null;
    vecTorque = null;
    vecMAEquip = null;
    mapStepinfo_Offset = null;
    mapStepinfo_Quaternion = null;
    mapStepinfo_Scale = null;
    if (geomInitUpper && geomInitUpper.isBufferGeometry) {
        geomInitUpper.dispose();
    }
    if (geomInitLower && geomInitLower.isBufferGeometry) {
        geomInitLower.dispose();
    }
    geomInitUpper = null;
    geomInitLower = null;
    if (G_lowGingivaMesh0 && G_lowGingivaMesh0.geometry) {
        RemoveRenderScene(G_lowGingivaMesh0);
        G_lowGingivaMesh0.geometry.dispose();
    }
    if (G_upperGingivaMesh0 && G_upperGingivaMesh0.geometry) {
        RemoveRenderScene(G_upperGingivaMesh0);
        G_upperGingivaMesh0.geometry.dispose();
    }
    if (G_lowGingivaMesh_Base0 && G_lowGingivaMesh_Base0.geometry) {
        RemoveRenderScene(G_lowGingivaMesh_Base0);
        G_lowGingivaMesh_Base0.geometry.dispose();
    }
    if (G_upperGingivaMesh_Base0 && G_upperGingivaMesh_Base0.geometry) {
        RemoveRenderScene(G_upperGingivaMesh_Base0);
        G_upperGingivaMesh_Base0.geometry.dispose();
    }
    G_lowGingivaMesh0 = null;
    G_upperGingivaMesh0 = null;
    G_lowGingivaMesh_Base0 = null;
    G_upperGingivaMesh_Base0 = null;

    parserMaf = new window.Module.CParsermaf();
    mafModel = {};
    vecTeeth = new Map();
    vecTeeth_Root = new Map();
    vecAttachment = new Map();
    vecCut = new Map();
    vecHat = new Map();
    vecTorque = new Map();
    vecMAEquip = new Map();
    mapStepinfo_Offset = new Map();
    mapStepinfo_Quaternion = new Map();
    mapStepinfo_Scale = new Map();
    stepBar = [];
    nTotalStep = -1;
    stepIndex = 0;
    jawDisplayState = 2;
    displayType = 0;
    geomInitUpper = {};
    geomInitLower = {};
    G_lowGingivaMesh0 = {};
    G_upperGingivaMesh0 = {};
    G_lowGingivaMesh_Base0 = {};
    G_upperGingivaMesh_Base0 = {};
}

export const GetRenderer = function () {
    return renderer;
}

export const GetCamera = function () {
    return camera;
}

export const GetCameras = function () {
    let ret = [];
    switch (displayType) {
        case 1: {
            ret.push((camera === cameraPersp ? cameraPerspL : cameraOrthoL));
            ret.push(camera);
        }
        break;
    case 2: {
        ret.push(camera);
        ret.push(camera);
    }
    break;
    case 3: {
        ret.push((camera === cameraPersp ? cameraPerspL : cameraOrthoL));
        ret.push(camera)
        ret.push((camera === cameraPersp ? cameraPerspR : cameraOrthoR));
        ret.push((camera === cameraPersp ? cameraPerspT : cameraOrthoT));
        ret.push((camera === cameraPersp ? cameraPerspB : cameraOrthoB));
    }
    break;
    default: {
        ret.push(camera)
    }
    }
    return ret;
}

export const AddRenderScene = function (object) {
    if (scene != undefined) {
        scene.add(object);
    }
}

export const GetRenderScene = function () {
    return scene;
}

export const RemoveRenderScene = function (object) {
    if (scene != undefined) {
        for (let i = scene.children.length - 1; i >= 0; i--) {
            let obj = scene.children[i];
            if (obj === object) {
                scene.remove(obj);
            }
        }
    }
}

export const SwitchProjectionMode = function (type) {
    if (camera != undefined && scene != undefined) {
        if (window.canvasAttr) {
            ww = window.canvasAttr.width;
            wh = window.canvasAttr.height;
            console.log(window.canvasAttr);
        } else {
            ww = window.innerWidth * 0.97;
            wh = window.innerHeight * 0.81;
        }
        let cpos = new window.THREE.Vector3(),
            cup = new window.THREE.Vector3();
        let perspZoom = camera === cameraPersp ? camera.zoom : camera.zoom / orthoSca;
        // let crot = new window.THREE.Euler();
        cpos.copy(camera.position);
        // crot.copy(camera.rotation);
        cup.copy(camera.up);
        if (type === 0) {
            camera = cameraPersp;
            camera.aspect = ww / wh;
            camera.position.copy(cpos);
            // camera.rotation.copy(crot);
            camera.up.copy(cup);
            camera.zoom = perspZoom;
            camera.lookAt(cameraTarget);
            camera.updateProjectionMatrix();
        } else {
            camera = cameraOrtho;
            camera.position.copy(cpos);
            // camera.rotation.copy(crot);
            camera.up.copy(cup);
            camera.zoom = perspZoom * orthoSca;
            camera.lookAt(cameraTarget);
            camera.updateProjectionMatrix();
        }
        if (controls != undefined) {
            controls.object = camera;
            controls.panSpeed = (type === 0) ? 0.3 : 1.5;
            controls.update();
        }
        renderer.clear();
        RenderScene(renderer);
    }
}

export const SetTrackballMode = function (type) {
    if (controls != undefined) {
        if (type === 0) {
            controls.mouseButtons = {
                LEFT: window.THREE.MOUSE.ROTATE,
                MIDDLE: window.THREE.MOUSE.ZOOM,
                RIGHT: window.THREE.MOUSE.PAN
            };
        } else {
            controls.mouseButtons = {
                LEFT: window.THREE.MOUSE.PAN,
                MIDDLE: window.THREE.MOUSE.ZOOM,
                RIGHT: window.THREE.MOUSE.ROTATE
            };
        }
    }
}

export const SetTeethTransparency = function (state) {
    let transparent = state ? 0.6 : 1.0;
    vecTeeth.forEach(function (value, key) {
        value.material.transparent = state;
        value.material.opacity = transparent;
    });
    vecTeeth_Root.forEach(function (value, key) {
        value.material.transparent = state;
        value.material.opacity = transparent;
    });
}

export const SetAttachmentTransparency = function (state) {
    let transparent = state ? 0.6 : 1.0;
    vecAttachment.forEach(function (value, key) {
        value.material.transparent = state;
        value.material.opacity = transparent;
        value.material.depthWrite = !state;
    });
}

export function GetCallback() {
    return funCallbackObj;
}

export const GetShowJaw = function () {
    return jawDisplayState;
}

/********************************** Boltion Index ************************************/

export const GetBoltonIndex36 = function () {
    let BoltionUR = GetBoltonIndexUR();
    let BoltionUL = GetBoltonIndexUL();
    let BoltionLR = GetBoltonIndexLR();
    let BoltionLL = GetBoltonIndexLL();
    let upper33 = 0,
        lower33 = 0,
        upper66 = 0,
        lower66 = 0;
    BoltionUR.forEach(function (value) {
        if ((value[0] % 10) <= 3) {
            upper33 += value[1];
        }
        upper66 += value[1];
    });
    BoltionUL.forEach(function (value) {
        if ((value[0] % 10) <= 3) {
            upper33 += value[1];
        }
        upper66 += value[1];
    });
    BoltionLR.forEach(function (value) {
        if ((value[0] % 10) <= 3) {
            lower33 += value[1];
        }
        lower66 += value[1];
    });
    BoltionLL.forEach(function (value) {
        if ((value[0] % 10) <= 3) {
            lower33 += value[1];
        }
        lower66 += value[1];
    });

    let bolton36 = [0, 0];
    if (upper33 == 0 || lower33 / upper33 > 0.772) {
        bolton36[0] = lower33 - upper33 * 0.772;
    } else {
        bolton36[0] = upper33 - lower33 / 0.772;
    }
    if (upper66 == 0 || lower66 / upper66 > 0.913) {
        bolton36[1] = lower66 - upper66 * 0.913;
    } else {
        bolton36[1] = upper66 - lower66 / 0.913;
    }
    return bolton36;
}

export const GetBoltonIndexUR = function () {
    let ret = [];
    let size = new window.THREE.Vector3();
    for (let key = 11; key != 17; key++) {
        let value = vecTeeth.get(key);
        if (value != undefined) {
            if (value.geometry.boundingBox == undefined || value.geometry.boundingBox.isEmpty()) {
                value.geometry.computeBoundingBox();
            }
            value.geometry.boundingBox.getSize(size);
            let row = [key, size.x];
            ret.push(row);
        } else {
            let row = [key, 0];
            ret.push(row);
        }
    }
    if (ret.length != 0) {
        ret.sort(function (a, b) {
            return a[0] - b[0]
        });
    }
    return ret;
}

export const GetBoltonIndexUL = function () {
    let ret = [];
    let size = new window.THREE.Vector3();
    for (let key = 21; key != 27; key++) {
        let value = vecTeeth.get(key);
        if (value != undefined) {
            if (value.geometry.boundingBox == undefined || value.geometry.boundingBox.isEmpty()) {
                value.geometry.computeBoundingBox();
            }
            value.geometry.boundingBox.getSize(size);
            let row = [key, size.x];
            ret.push(row);
        } else {
            let row = [key, 0];
            ret.push(row);
        }
    }
    if (ret.length != 0) {
        ret.sort(function (a, b) {
            return a[0] - b[0]
        });
    }
    return ret;
}

export const GetBoltonIndexLR = function () {
    let ret = [];
    let size = new window.THREE.Vector3();
    for (let key = 41; key != 47; key++) {
        let value = vecTeeth.get(key);
        if (value != undefined) {
            if (value.geometry.boundingBox == undefined || value.geometry.boundingBox.isEmpty()) {
                value.geometry.computeBoundingBox();
            }
            value.geometry.boundingBox.getSize(size);
            let row = [key, size.x];
            ret.push(row);
        } else {
            let row = [key, 0];
            ret.push(row);
        }
    }
    if (ret.length != 0) {
        ret.sort(function (a, b) {
            return a[0] - b[0]
        });
    }
    return ret;
}

export const GetBoltonIndexLL = function () {
    let ret = [];
    let size = new window.THREE.Vector3();
    for (let key = 31; key != 37; key++) {
        let value = vecTeeth.get(key);
        if (value != undefined) {
            if (value.geometry.boundingBox == undefined || value.geometry.boundingBox.isEmpty()) {
                value.geometry.computeBoundingBox();
            }
            value.geometry.boundingBox.getSize(size);
            let row = [key, size.x];
            ret.push(row);
        } else {
            let row = [key, 0];
            ret.push(row);
        }
    }
    if (ret.length != 0) {
        ret.sort(function (a, b) {
            return a[0] - b[0]
        });
    }
    return ret;
}

/********************************** Comparison ************************************/

export const GetInitialTeeth = function (jaw) {
    if (jaw) {
        if (geomInitUpper && geomInitUpper.isBufferGeometry) {
            // return geomInitUpper;
        } else {
            geomInitUpper = new window.THREE.BufferGeometry();
            let geomInit = new window.THREE.Geometry();
            let mat = new window.THREE.Matrix4();
            vecTeeth.forEach(function (value, key) {
                if (Math.abs(key) < 30) {
                    let offset = mapStepinfo_Offset.get(key)[0]; // 偏移量
                    let quat = mapStepinfo_Quaternion.get(key)[0]; // 四元数
                    let sca = mapStepinfo_Scale.get(key)[0];
                    if (sca.x != 0 && sca.y != 0 && sca.z != 0) {
                        let geom = new window.THREE.Geometry();
                        mat.compose(offset, quat, sca);
                        geom.fromBufferGeometry(value.geometry);
                        geomInit.merge(geom, mat, 0);
                        geom.dispose();
                        geom = null;
                    }
                }
            });
            geomInitUpper.fromGeometry(geomInit);
            geomInit.dispose();
            geomInit = null;
        }
        return geomInitUpper;
    } else {
        if (geomInitLower && geomInitLower.isBufferGeometry) {
            // return geomInitLower;
        } else {
            geomInitLower = new window.THREE.BufferGeometry();
            let geomInit = new window.THREE.Geometry();
            let mat = new window.THREE.Matrix4();
            vecTeeth.forEach(function (value, key) {
                if (Math.abs(key) > 30) {
                    let offset = mapStepinfo_Offset.get(key)[0]; // 偏移量
                    let quat = mapStepinfo_Quaternion.get(key)[0]; // 四元数
                    let sca = mapStepinfo_Scale.get(key)[0];
                    if (sca.x != 0 && sca.y != 0 && sca.z != 0) {
                        let geom = new window.THREE.Geometry();
                        mat.compose(offset, quat, sca);
                        geom.fromBufferGeometry(value.geometry);
                        geomInit.merge(geom, mat, 0);
                        geom.dispose();
                        geom = null;
                    }
                }
            });
            geomInitLower.fromGeometry(geomInit);
            geomInit.dispose();
            geomInit = null;
        }
        return geomInitLower;
    }
};

/********************************** ArchWidth ************************************/

export const GetAnteriorArchWidth = function (jaw, step) {
    let ret = [];
    let idL = jaw ? 23 : 33,
        idR = jaw ? 13 : 43;
    let meshL = vecTeeth.get(idL);
    let meshR = vecTeeth.get(idR);
    if (meshL != undefined && meshR != undefined) {
        let ptL = CalcuArchReferencePoint(3, meshL);
        let ptR = CalcuArchReferencePoint(3, meshR);
        let offsetL = mapStepinfo_Offset.get(idL)[step];
        let quatL = mapStepinfo_Quaternion.get(idL)[step];
        let scaL = mapStepinfo_Scale.get(idL)[step];
        let offsetR = mapStepinfo_Offset.get(idR)[step];
        let quatR = mapStepinfo_Quaternion.get(idR)[step];
        let scaR = mapStepinfo_Scale.get(idR)[step];
        if (scaL.x != 0 && scaL.y != 0 && scaL.z != 0 && scaR.x != 0 && scaR.y != 0 && scaR.z != 0) {
            ptL.multiply(scaL).applyQuaternion(quatL).add(offsetL);
            ptR.multiply(scaR).applyQuaternion(quatR).add(offsetR);
            ret.push(ptL);
            ret.push(ptR);
            // console.log(ptL.sub(ptR).length());
        }
    }
    return ret;
};

export const GetPosteriorArchWidth = function (jaw, step) {
    let ret = [];
    let idL = jaw ? 26 : 36,
        idR = jaw ? 16 : 46;
    let meshL = vecTeeth.get(idL);
    let meshR = vecTeeth.get(idR);
    if (meshL != undefined && meshR != undefined) {
        let ptL = CalcuArchReferencePoint(6, meshL);
        let ptR = CalcuArchReferencePoint(6, meshR);
        let offsetL = mapStepinfo_Offset.get(idL)[step];
        let quatL = mapStepinfo_Quaternion.get(idL)[step];
        let scaL = mapStepinfo_Scale.get(idL)[step];
        let offsetR = mapStepinfo_Offset.get(idR)[step];
        let quatR = mapStepinfo_Quaternion.get(idR)[step];
        let scaR = mapStepinfo_Scale.get(idR)[step];
        if (scaL.x != 0 && scaL.y != 0 && scaL.z != 0 && scaR.x != 0 && scaR.y != 0 && scaR.z != 0){
            ptL.multiply(scaL).applyQuaternion(quatL).add(offsetL);
            ptR.multiply(scaR).applyQuaternion(quatR).add(offsetR);
            ret.push(ptL);
            ret.push(ptR);
            // console.log(ptL.sub(ptR).length());
        }
    }
    return ret;
};

function CalcuArchReferencePoint(type, mesh) {
    let archpt = new window.THREE.Vector3();
    let geom = new window.THREE.Geometry();
    geom.fromBufferGeometry(mesh.geometry);
    geom.mergeVertices();
    if (mesh.geometry.boundingBox == undefined || mesh.geometry.boundingBox.isEmpty()) {
        mesh.geometry.computeBoundingBox();
    }
    if (type === 3) {
        let refdist = 0;
        geom.vertices.forEach(function (pt) {
            if (pt.z < 0) {
                if (Math.abs(pt.x) < 0.5) {
                    if (pt.z < refdist) {
                        refdist = pt.z;
                        archpt.copy(pt);
                    }
                }
            }
        });
    } else if (type === 6) {
        let refdist = -100,
            maxrng = 0.3 * mesh.geometry.boundingBox.max.z;
        geom.vertices.forEach(function (pt) {
            if (pt.z < maxrng) {
                let len = pt.length();
                if (len < 1 || Math.abs(pt.z) / len > 0.9659) {
                    if (pt.z > refdist) {
                        refdist = pt.z;
                        archpt.copy(pt);
                    }
                }
            }
        });
    }
    return archpt;
}

/********************************** MoveData ************************************/

export const GetTeethId = function () {
    let ret = [];
    vecTeeth.forEach(function (value, key) {
        if (!IsDentureVacuole(mapStepinfo_Scale.get(key))) {
            ret.push(Math.abs(key));
        }
    });
    return ret;
};

export const GetMoveData = function (step, toothid) {
    let ret = [];
    let ptOffset = new window.CVector(),
        ptRot = new window.CVector();
    if (JSON.stringify(mafModel) == '{}') {
        mafModel = parserMaf.GetModel();
    }
    if (mafModel.GetToothMoveData(toothid, step, 1, ptOffset, ptRot)) {
        ret.push(ptOffset.GetX());
        ret.push(ptOffset.GetY());
        ret.push(ptOffset.GetZ());
        ret.push(ptRot.GetX());
        ret.push(ptRot.GetY());
        ret.push(ptRot.GetZ());
    }
    return ret;
};

/********************************** ToothLabelPosition ************************************/

var toothlabelwidth = CalcuToothidSize();

export const GetToothIdPosition = function (toothid, step) {
    let ret = [new window.THREE.Vector3(), new window.THREE.Vector3(), new window.THREE.Vector3(), new window.THREE.Vector3()];
    let mesh = vecTeeth.get(toothid);
      let sca = mapStepinfo_Scale.get(toothid)[step];
      if (mesh && (sca.x != 0 && sca.y != 0 && sca.z != 0)) {
        if (!mesh.geometry.boundingBox || mesh.geometry.boundingBox.isEmpty()) {
            mesh.geometry.computeBoundingBox();
        }
        let center = new window.THREE.Vector3();
        let size = new window.THREE.Vector3();
        mesh.geometry.boundingBox.getCenter(center);
        mesh.geometry.boundingBox.getSize(size);
        if ((toothid % 10) > 5) {
            center.z += size.z * 0.15;
        }
        if ((10 < toothid && toothid < 20) || (30 < toothid && toothid < 40)) {
            ret.map(function (value) {
                value.setY(mesh.geometry.boundingBox.min.y - 0.1);
            });
        } else {
            ret.map(function (value) {
                value.setY(mesh.geometry.boundingBox.max.y + 0.1);
            });
        }
        if ((10 < toothid && toothid < 20) || (40 < toothid && toothid < 50)) {
            ret[0].setX(center.x - toothlabelwidth);
            ret[1].setX(center.x + toothlabelwidth);
            ret[2].setX(center.x + toothlabelwidth);
            ret[3].setX(center.x - toothlabelwidth);
        } else {
            ret[0].setX(center.x + toothlabelwidth);
            ret[1].setX(center.x - toothlabelwidth);
            ret[2].setX(center.x - toothlabelwidth);
            ret[3].setX(center.x + toothlabelwidth);
        }
        if (toothid < 30) {
            ret[0].setZ(center.z - size.z * 0.2 - toothlabelwidth);
            ret[1].setZ(center.z - size.z * 0.2 - toothlabelwidth);
            ret[2].setZ(center.z - size.z * 0.2 + toothlabelwidth);
            ret[3].setZ(center.z - size.z * 0.2 + toothlabelwidth);
        } else {
            ret[0].setZ(center.z - size.z * 0.2 + toothlabelwidth);
            ret[1].setZ(center.z - size.z * 0.2 + toothlabelwidth);
            ret[2].setZ(center.z - size.z * 0.2 - toothlabelwidth);
            ret[3].setZ(center.z - size.z * 0.2 - toothlabelwidth);
        }
        let offset = mapStepinfo_Offset.get(toothid)[step];
        let quat = mapStepinfo_Quaternion.get(toothid)[step];
        ret.map(function(value) {
            value.multiply(sca).applyQuaternion(quat).add(offset);
        });
    }
    return ret;
}

function CalcuToothidSize() {
    let width = 100;
    let size = new window.THREE.Vector3();
    vecTeeth.forEach(function (value, key) {
        if (!value.geometry.boundingBox || value.geometry.boundingBox.isEmpty()) {
            value.geometry.computeBoundingBox();
        }
        value.geometry.boundingBox.getSize(size);
        width = Math.min(width, size.x * 0.3);
        if ((Math.abs(key) % 10) > 3) {
            width = Math.min(width, size.z * 0.33);
        }
    });
    return (width > 10) ? 1.5 : width;
}

/************************************ IPR **************************************/

export const GetIPRData = function (jaw) {
    let ret = [];
    if (JSON.stringify(parserMaf) == '{}') {
        return ret;
    }
    let paIPR = new window.CIPRPair();
    let IPRSize = parserMaf.GetIPRPairSize();
    for (let i = 0; i != IPRSize; i++) {
        if (parserMaf.GetIPRPair(i, paIPR)) {
            if ((paIPR.GetToothId1() < 30) == jaw) {
                let IPRData = {
                    toothid1: paIPR.GetToothId1(),
                    toothid2: paIPR.GetToothId2(),
                    step: paIPR.GetStep(),
                    ipr: paIPR.GetIPR()
                };
                ret.push(IPRData);
            }
        }
    }
    return ret;
}

export const GetIPRPosition = function (toothid1, toothid2, step) {
    let ret = [new window.THREE.Vector3(), new window.THREE.Vector3()];
    let mesh1 = vecTeeth.get(toothid1);
    let mesh2 = vecTeeth.get(toothid2);
    if (mesh1 != undefined && mesh2 != undefined) {
        if (mesh1.geometry.boundingBox == undefined || mesh1.geometry.boundingBox.isEmpty()) {
            mesh1.geometry.computeBoundingBox();
        }
        if (mesh2.geometry.boundingBox == undefined || mesh2.geometry.boundingBox.isEmpty()) {
            mesh2.geometry.computeBoundingBox();
        }
        let offset1 = mapStepinfo_Offset.get(toothid1)[step];
        let offset2 = mapStepinfo_Offset.get(toothid2)[step];
        let quat1 = mapStepinfo_Quaternion.get(toothid1)[step];
        let quat2 = mapStepinfo_Quaternion.get(toothid2)[step];
        let center1 = new window.THREE.Vector3(),
            center2 = new window.THREE.Vector3(),
            size1 = new window.THREE.Vector3(),
            size2 = new window.THREE.Vector3();
        mesh1.geometry.boundingBox.getSize(size1);
        mesh2.geometry.boundingBox.getSize(size2);
        mesh1.geometry.boundingBox.getCenter(center1);
        mesh2.geometry.boundingBox.getCenter(center2);
        if ((10 < toothid1 && toothid1 < 20) || (30 < toothid1 && toothid1 < 40)) {
            center1.y -= size1.y / 3.0;
        } else {
            center1.y += size1.y / 3.0;
        }
        if ((10 < toothid2 && toothid2 < 20) || (30 < toothid2 && toothid2 < 40)) {
            center2.y -= size2.y / 3.0;
        } else {
            center2.y += size2.y / 3.0;
        }
        center1.applyQuaternion(quat1).add(offset1);
        center2.applyQuaternion(quat2).add(offset2);
        let len = 0.67 * (size1.z + size2.z);
        ret[0].lerpVectors(center1, center2, size1.x / (size1.x + size2.x));
        let dir = new window.THREE.Vector3();
        dir.set(ret[0].x, ret[0].y, 0);
        dir.normalize();
        let hight = 18.0 / (10.0 + Math.max(toothid1 % 10, toothid2 % 10));
        ret[1].subVectors(center2, center1).cross(new window.THREE.Vector3(0, 0, -1)).normalize().add(dir).normalize().multiplyScalar(len).add(ret[0]);
        ret[1].z += len * hight / (toothid1 < 30 ? -3.0 : 3.0);
    }
    return ret;
}

/*********************************** Occlusion *************************************/

export const GetOcclusalSurface = function (step) {
    let ret = [];
    if (JSON.stringify(parserMaf) == '{}') {
        return ret;
    }
    let occl = {};
    occl = parserMaf.UpdateCollisionDetection(step);
    if (JSON.stringify(occl) == '{}') {
        return ret;
    }
    let vertex = new window.CVector();
    let mat = new window.THREE.Matrix4();
    let vn, fn, occlSize = occl.GetOcclusalInfoSize();
    for (let i = 0; i != occlSize; i++) {
        let occlInfo = {};
        occlInfo = occl.GetOcclusalInfo(i);
        if (JSON.stringify(occlInfo) == '{}') {
            continue;
        }
        let nFDI = occlInfo.GetToothFDI();
        if (mapStepinfo_Offset.get(nFDI) == undefined || mapStepinfo_Offset.get(nFDI).length <= step) {
            continue;
        }
        let offset = mapStepinfo_Offset.get(nFDI)[step];
        let quat = mapStepinfo_Quaternion.get(nFDI)[step];
        let sca = mapStepinfo_Scale.get(nFDI)[step];
        if (sca.x == 0 || sca.y == 0 || sca.z == 0) {
            continue;
        }
        let geom = new window.THREE.Geometry();
        vn = occlInfo.GetMeshVertex();
        fn = occlInfo.GetMeshFace();
        for (let j = 0; j < vn; j++) {
            if (occlInfo.GetVertexXYZ(vertex, j)) {
                geom.vertices.push(new window.THREE.Vector3(vertex.GetX(), vertex.GetY(), vertex.GetZ()));
            }
        }
        for (let j = 0; j < fn; j++) {
            if (occlInfo.GetFaceIndex(vertex, j)) {
                geom.faces.push(new window.THREE.Face3(vertex.GetX(), vertex.GetY(), vertex.GetZ()));
            }
        }
        mat.compose(offset, quat, sca);
        geom.applyMatrix(mat);
        geom.computeVertexNormals();
        geom.computeFaceNormals();
        let bufferGeom = new window.THREE.BufferGeometry();
        bufferGeom.fromGeometry(geom);
        let occlmesh = {
            mesh: bufferGeom,
            depth: occlInfo.GetDepth(),
            jaw: (Math.abs(nFDI) < 30)
        };
        ret.push(occlmesh);
        geom.dispose();
        geom = null;
    }
    occl.ReleaseOcclusalInfo();
    occl = null;
    return ret;
}

/*********************************** StepTable *************************************/

export const GetAttachmentRange = function () {
    let ret = [];
    if (JSON.stringify(mafModel) == '{}') {
        mafModel = parserMaf.GetModel();
    }
    let nAttachListSize = mafModel.GetAttachListSize();
    for (let i = 0; i < nAttachListSize; i++) {
        let pShell = mafModel.GetAttachment(i);
        let range = [Math.abs(pShell.GetParentFDI()), pShell.GetAttachmentStartStep(), pShell.GetAttachmentEndStep()];
        ret.push(range);
    }
    return ret;
}

export const GetTeethStepTable = function (toothid) {
    let ret = [];
    if (vecTeeth.get(toothid) != undefined) {
        if (JSON.stringify(mafModel) == '{}') {
            mafModel = parserMaf.GetModel();
        }
        let nShellListSize = mafModel.GetShellListSize();
        for (let i = 0; i < nShellListSize; i++) {
            let pShell = mafModel.GetShell(i);
            if (toothid == pShell.GetToothFDI()) {
                let segment = new window.CMoveSegment();
                if (pShell.GetMoveSegment(segment)) {
                    let segsize = segment.GetSegmentSize();
                    for (let j = 0; j < segsize; j++) {
                        let range = [segment.GetSegmentStart(j), segment.GetSegmentEnd(j), 0];
                        ret.push(range);
                    }
                    // console.log(toothid);
                    // console.log(ret);
                }
                break;
            }
        }
    }
    return ret;
}

/*********************************** 新增 2022.1.4 by孙懿 *************************************/

export const GetRayCastingMesh = function (x, y) {
    let ret = 0;
    if (displayType === 0) {
        let mouse = new window.THREE.Vector2();
        mouse.x = (x / ww) * 2 - 1;
        mouse.y = -(y / wh) * 2 + 1;
        let raycaster = new window.THREE.Raycaster();
        raycaster.setFromCamera(mouse, camera);
        let intersects = raycaster.intersectObjects(scene.children);
        let object = intersects.length != 0 ? intersects[0].object : null;
        for (let it of vecTeeth.entries()) {
            if (it[1].visible && it[1] === object) {
                ret = it[0];
                it[1].material = selectMaterial;
            } else {
                it[1].material = toothMaterial;
            }
        }
    }
    console.log(ret);
    return ret;
}

export const GetOverJawData = function (step, toothid) {
    let ret = [0, 0];
    if (JSON.stringify(mafModel) == '{}') {
        mafModel = parserMaf.GetModel();
    }
    let ptOBOJ = new window.CVector();
    if (mafModel.GetOBOJData(toothid, step, ptOBOJ)) {
        ret[0] = ptOBOJ.GetX();
        ret[1] = ptOBOJ.GetY();
    }
    return ret;
}

// 2: left; 3: front; 4: right; 5: upper jaw; 6: lower jaw; 45: perspective
export const SwitchView = function (type) {
    if (type === 2) {
        // left side
        pointModel(0);
        pointModel(2);
    } else if (type === 3) {
        // front side
        pointModel(0);
        pointModel(3);
    } else if (type === 4) {
        // right side
        pointModel(0);
        pointModel(4);
    } else if (type === 5) {
        // upper jaw
        pointModel(6);
    } else if (type === 6) {
        // lower jaw
        pointModel(5);
    } else if (type === 45) {
        // perspective
        pointModel(45);
    }
}

function Canvas2Image(scenecanvas) {
    let image = new Image();
    image.src = scenecanvas.toDataURL("image/png");
    return image;
}

// 0: current; 1: perspective; 2: front; 3: duel; 4: l-f-r; 5: occlusal; 6: composite
export const GetGIFSourceImage = function (type, w, h) {
    let ret = [],
        curstep = stepIndex,
        jawState = jawDisplayState,
        dispType = displayType,
        w0 = ww,
        h0 = wh;
    let cameraState = camera === cameraPersp ? 0 : 1;
    cancelAnimationFrame(animate);
    ww = w;
    wh = h;
    switch (type) {
        case 1: {
            displayType = 0;
            SwitchView(45);
        }
        break;
    case 2: {
        SetMultipleView(0);
    }
    break;
    case 3: {
        SetMultipleView(5);
    }
    break;
    case 4: {
        SetMultipleView(4);
    }
    break;
    case 5: {
        SetMultipleView(1);
    }
    break;
    case 6: {
        SetMultipleView(3);
    }
    break;
    default: {
        displayType = 0;
    }
    break;
    }
    camera = cameraPersp;
    cameraPersp.near = Math.max(10, cameraPersp.position.length() - 100);
    cameraPersp.far = cameraPersp.position.length() + 100;
    switch (displayType) {
        case 1: {
            cameraPersp.aspect = ww / (2.0 * wh);
            cameraPerspL.aspect = ww / (2.0 * wh);
            cameraPerspL.near = cameraPersp.near;
            cameraPerspL.far = cameraPersp.far;
            cameraPerspL.updateProjectionMatrix();
        }
        break;
    case 2: {
        cameraPersp.aspect = ww / (2.0 * wh);
    }
    break;
    case 3: {
        cameraPersp.aspect = ww / (1.5 * wh);
        cameraPerspL.aspect = ww / (1.5 * wh);
        cameraPerspL.near = Math.max(10, cameraPerspL.position.length() - 100);
        cameraPerspL.far = cameraPerspL.position.length() + 100;
        cameraPerspL.updateProjectionMatrix();
        cameraPerspR.aspect = ww / (1.5 * wh);
        cameraPerspR.near = Math.max(10, cameraPerspR.position.length() - 100);
        cameraPerspR.far = cameraPerspR.position.length() + 100;
        cameraPerspR.updateProjectionMatrix();
        cameraPerspT.aspect = ww / wh;
        cameraPerspT.near = Math.max(10, cameraPerspT.position.length() - 100);
        cameraPerspT.far = cameraPerspT.position.length() + 100;
        cameraPerspT.updateProjectionMatrix();
        cameraPerspB.aspect = ww / wh;
        cameraPerspB.near = Math.max(10, cameraPerspB.position.length() - 100);
        cameraPerspB.far = cameraPerspB.position.length() + 100;
        cameraPerspB.updateProjectionMatrix();
    }
    break;
    case 4: {
        cameraPersp.aspect = ww / (3.0 * wh);
        cameraPerspL.aspect = ww / (3.0 * wh);
        cameraPerspL.near = Math.max(10, cameraPerspL.position.length() - 100);
        cameraPerspL.far = cameraPerspL.position.length() + 100;
        cameraPerspL.updateProjectionMatrix();
        cameraPerspR.aspect = ww / (3.0 * wh);
        cameraPerspR.near = Math.max(10, cameraPerspR.position.length() - 100);
        cameraPerspR.far = cameraPerspR.position.length() + 100;
        cameraPerspR.updateProjectionMatrix();
    }
    break;
    case 5: {
        cameraPersp.aspect = ww / (2.0 * wh);
        cameraPerspL.aspect = ww / (2.0 * wh);
        cameraPerspL.near = cameraPersp.near;
        cameraPerspL.far = cameraPersp.far;
        cameraPerspL.updateProjectionMatrix();
    }
    break;
    default: {
        cameraPersp.aspect = ww / wh;
    }
    }
    cameraPersp.updateProjectionMatrix();
    controls.noRotate = true;
    controls.noZoom = true;
    controls.noPan = true;
    controls.update();

    let renderer1 = new window.THREE.WebGLRenderer({
        antialias: true,
        alpha: true,
        logarithmicDepthBuffer: false,
        preserveDrawingBuffer: true
    });
    renderer1.setPixelRatio(window.devicePixelRatio); // 提示清晰度
    renderer1.setSize(w, h);
    renderer1.shadowMap.enabled = true;
    renderer1.shadowMap.type = window.THREE.PCFSoftShadowMap;
    renderer1.sortObjects = false;
    renderer1.autoClear = (type < 3) ? true : false;
    renderer1.setClearColor("rgb(255,255,255)", 1);
    renderer1.clear();
    for (let i = 0; i < nTotalStep; i++) {
        playModel(i);
        RenderScene(renderer1);
        ret[i] = Canvas2Image(renderer1.domElement);
    }
    renderer1.dispose();
    ww = w0;
    wh = h0;
    if (cameraState !== 0) SwitchProjectionMode(cameraState);
    SetMultipleView(dispType);
    jawDisplayState = jawState;
    playModel(curstep);
    controls.noRotate = false;
    controls.noZoom = false;
    controls.noPan = false;
    animate();
    return ret;
}

function SetMultipleView(type) {
    if (type === displayType) return;
    if (G_lowGingivaMesh0 && G_lowGingivaMesh0.geometry) {
        RemoveRenderScene(G_lowGingivaMesh0);
        G_lowGingivaMesh0.geometry.dispose();
        G_lowGingivaMesh0 = null;
    }
    if (G_upperGingivaMesh0 && G_upperGingivaMesh0.geometry) {
        RemoveRenderScene(G_upperGingivaMesh0);
        G_upperGingivaMesh0.geometry.dispose();
        G_upperGingivaMesh0 = null;
    }
    if (G_lowGingivaMesh_Base0 && G_lowGingivaMesh_Base0.geometry) {
        RemoveRenderScene(G_lowGingivaMesh_Base0);
        G_lowGingivaMesh_Base0.geometry.dispose();
        G_lowGingivaMesh_Base0 = null;
    }
    if (G_upperGingivaMesh_Base0 && G_upperGingivaMesh_Base0.geometry) {
        RemoveRenderScene(G_upperGingivaMesh_Base0);
        G_upperGingivaMesh_Base0.geometry.dispose();
        G_upperGingivaMesh_Base0 = null;
    }
    G_lowGingivaMesh0 = {};
    G_upperGingivaMesh0 = {};
    G_lowGingivaMesh_Base0 = {};
    G_upperGingivaMesh_Base0 = {};
    switch (type) {
        case 1: {
            displayType = 1;
            renderer.autoClear = false;
            cameraPerspL.position.set(0, 0, nCameraDistance);
            cameraPerspL.rotation.set(0, 0, 0);
            cameraPerspL.up.x = 0;
            cameraPerspL.up.y = -1;
            cameraPerspL.up.z = 0;
            cameraPerspL.zoom = 0.75;
            cameraPerspL.lookAt(cameraTarget);
            cameraPerspL.updateProjectionMatrix();
            cameraOrthoL.position.set(0, 0, nCameraDistance);
            cameraOrthoL.rotation.set(0, 0, 0);
            cameraOrthoL.up.x = 0;
            cameraOrthoL.up.y = -1;
            cameraOrthoL.up.z = 0;
            cameraOrthoL.zoom = 0.75 * orthoSca;
            cameraOrthoL.lookAt(cameraTarget);
            cameraOrthoL.updateProjectionMatrix();
            cameraPersp.position.set(0, 0, -nCameraDistance);
            cameraPersp.rotation.set(0, 0, 0);
            cameraPersp.up.x = 0;
            cameraPersp.up.y = 1;
            cameraPersp.up.z = 0;
            cameraPersp.lookAt(cameraTarget);
            cameraPersp.updateProjectionMatrix();
            cameraPersp.zoom = 0.75;
            cameraOrtho.position.set(0, 0, -nCameraDistance);
            cameraOrtho.rotation.set(0, 0, 0);
            cameraOrtho.up.x = 0;
            cameraOrtho.up.y = 1;
            cameraOrtho.up.z = 0;
            cameraOrtho.zoom = 0.75 * orthoSca;
            cameraOrtho.lookAt(cameraTarget);
            cameraOrtho.updateProjectionMatrix();
        }
        break;
    case 2: {
        displayType = 2;
        renderer.autoClear = false;
        cameraPersp.zoom = 0.75;
        cameraOrtho.zoom = 0.75 * orthoSca;
        SwitchView(3);
        playModel(0);
        G_lowGingivaMesh0 = G_lowGingivaMesh.clone();
        G_upperGingivaMesh0 = G_upperGingivaMesh.clone();
        G_lowGingivaMesh_Base0 = G_lowGingivaMesh_Base.clone();
        G_upperGingivaMesh_Base0 = G_upperGingivaMesh_Base.clone();
        scene.add(G_lowGingivaMesh0);
        scene.add(G_upperGingivaMesh0);
        scene.add(G_lowGingivaMesh_Base0);
        scene.add(G_upperGingivaMesh_Base0);
        playModel(nTotalStep - 1);
    }
    break;
    case 3: {
        displayType = 3;
        renderer.autoClear = false;
        cameraPersp.position.set(0, -nCameraDistance, 0);
        cameraPersp.rotation.set(0, 0, 0);
        cameraPersp.up.x = 0;
        cameraPersp.up.y = 0;
        cameraPersp.up.z = -1;
        cameraPersp.zoom = 0.95;
        cameraPersp.lookAt(cameraTarget);
        cameraPersp.updateProjectionMatrix();
        cameraOrtho.position.set(0, -nCameraDistance, 0);
        cameraOrtho.rotation.set(0, 0, 0);
        cameraOrtho.up.x = 0;
        cameraOrtho.up.y = 0;
        cameraOrtho.up.z = -1;
        cameraOrtho.zoom = 0.95 * orthoSca;
        cameraOrtho.lookAt(cameraTarget);
        cameraOrtho.updateProjectionMatrix();
        cameraPerspL.position.set(nCameraDistance, 0, 0);
        cameraPerspL.rotation.set(1.57, 1.57, 0);
        cameraPerspL.up.x = 0;
        cameraPerspL.up.y = 0;
        cameraPerspL.up.z = -1;
        cameraPerspL.zoom = 0.95;
        cameraPerspL.lookAt(cameraTarget);
        cameraPerspL.updateProjectionMatrix();
        cameraOrthoL.position.set(nCameraDistance, 0, 0);
        cameraOrthoL.rotation.set(1.57, 1.57, 0);
        cameraOrthoL.up.x = 0;
        cameraOrthoL.up.y = 0;
        cameraOrthoL.up.z = -1;
        cameraOrthoL.lookAt(cameraTarget);
        cameraOrthoL.updateProjectionMatrix();
        cameraOrthoL.zoom = 0.95 * orthoSca;
        cameraPerspR.position.set(-nCameraDistance, 0, 0);
        cameraPerspR.rotation.set(1.57, -1.57, 0);
        cameraPerspR.up.x = 0;
        cameraPerspR.up.y = 0;
        cameraPerspR.up.z = -1;
        cameraPerspR.zoom = 0.95;
        cameraPerspR.lookAt(cameraTarget);
        cameraPerspR.updateProjectionMatrix();
        cameraOrthoR.position.set(-nCameraDistance, 0, 0);
        cameraOrthoR.rotation.set(1.57, -1.57, 0);
        cameraOrthoR.up.x = 0;
        cameraOrthoR.up.y = 0;
        cameraOrthoR.up.z = -1;
        cameraOrthoR.zoom = 0.95 * orthoSca;
        cameraOrthoR.lookAt(cameraTarget);
        cameraOrthoR.updateProjectionMatrix();
        cameraPerspT.position.set(0, 0, nCameraDistance);
        cameraPerspT.rotation.set(0, 0, 0);
        cameraPerspT.up.x = 0;
        cameraPerspT.up.y = -1;
        cameraPerspT.up.z = 0;
        cameraPerspT.zoom = 0.95;
        cameraPerspT.lookAt(cameraTarget);
        cameraPerspT.updateProjectionMatrix();
        cameraOrthoT.position.set(0, 0, nCameraDistance);
        cameraOrthoT.rotation.set(0, 0, 0);
        cameraOrthoT.up.x = 0;
        cameraOrthoT.up.y = -1;
        cameraOrthoT.up.z = 0;
        cameraOrthoT.zoom = 0.95 * orthoSca;
        cameraOrthoT.lookAt(cameraTarget);
        cameraOrthoT.updateProjectionMatrix();
        cameraPerspB.position.set(0, 0, -nCameraDistance);
        cameraPerspB.rotation.set(0, 0, 0);
        cameraPerspB.up.x = 0;
        cameraPerspB.up.y = 1;
        cameraPerspB.up.z = 0;
        cameraPerspB.zoom = 0.95;
        cameraPerspB.lookAt(cameraTarget);
        cameraPerspB.updateProjectionMatrix();
        cameraOrthoB.position.set(0, 0, -nCameraDistance);
        cameraOrthoB.rotation.set(0, 0, 0);
        cameraOrthoB.up.x = 0;
        cameraOrthoB.up.y = 1;
        cameraOrthoB.up.z = 0;
        cameraOrthoB.zoom = 0.95 * orthoSca;
        cameraOrthoB.lookAt(cameraTarget);
        cameraOrthoB.updateProjectionMatrix();
    }
    break;
    case 4: {
        displayType = 4;
        renderer.autoClear = false;
        cameraPersp.position.set(0, -nCameraDistance, 0);
        cameraPersp.rotation.set(0, 0, 0);
        cameraPersp.up.x = 0;
        cameraPersp.up.y = 0;
        cameraPersp.up.z = -1;
        cameraPersp.zoom = 0.75;
        cameraPersp.lookAt(cameraTarget);
        cameraPersp.updateProjectionMatrix();
        cameraOrtho.position.set(0, -nCameraDistance, 0);
        cameraOrtho.rotation.set(0, 0, 0);
        cameraOrtho.up.x = 0;
        cameraOrtho.up.y = 0;
        cameraOrtho.up.z = -1;
        cameraOrtho.zoom = 0.75 * orthoSca;
        cameraOrtho.lookAt(cameraTarget);
        cameraOrtho.updateProjectionMatrix();
        cameraPerspL.position.set(nCameraDistance, 0, 0);
        cameraPerspL.rotation.set(1.57, 1.57, 0);
        cameraPerspL.up.x = 0;
        cameraPerspL.up.y = 0;
        cameraPerspL.up.z = -1;
        cameraPerspL.zoom = 0.75;
        cameraPerspL.lookAt(cameraTarget);
        cameraPerspL.updateProjectionMatrix();
        cameraOrthoL.position.set(nCameraDistance, 0, 0);
        cameraOrthoL.rotation.set(1.57, 1.57, 0);
        cameraOrthoL.up.x = 0;
        cameraOrthoL.up.y = 0;
        cameraOrthoL.up.z = -1;
        cameraOrthoL.zoom = 0.75 * orthoSca;
        cameraOrthoL.lookAt(cameraTarget);
        cameraOrthoL.updateProjectionMatrix();
        cameraPerspR.position.set(-nCameraDistance, 0, 0);
        cameraPerspR.rotation.set(1.57, -1.57, 0);
        cameraPerspR.up.x = 0;
        cameraPerspR.up.y = 0;
        cameraPerspR.up.z = -1;
        cameraPerspR.zoom = 0.75;
        cameraPerspR.lookAt(cameraTarget);
        cameraPerspR.updateProjectionMatrix();
        cameraOrthoR.position.set(-nCameraDistance, 0, 0);
        cameraOrthoR.rotation.set(1.57, -1.57, 0);
        cameraOrthoR.up.x = 0;
        cameraOrthoR.up.y = 0;
        cameraOrthoR.up.z = -1;
        cameraOrthoR.zoom = 0.75 * orthoSca;
        cameraOrthoR.lookAt(cameraTarget);
        cameraOrthoR.updateProjectionMatrix();
    }
    break;
    case 5: {
        displayType = 5;
        renderer.autoClear = false;
        cameraPerspL.position.set(nCameraDistance, 0, 0);
        cameraPerspL.rotation.set(1.57, 1.57, 0);
        cameraPerspL.up.x = 0;
        cameraPerspL.up.y = 0;
        cameraPerspL.up.z = -1;
        cameraPerspL.zoom = 0.75;
        cameraPerspL.lookAt(cameraTarget);
        cameraPerspL.updateProjectionMatrix();
        cameraOrthoL.position.set(nCameraDistance, 0, 0);
        cameraOrthoL.rotation.set(1.57, 1.57, 0);
        cameraOrthoL.up.x = 0;
        cameraOrthoL.up.y = 0;
        cameraOrthoL.up.z = -1;
        cameraOrthoL.zoom = 0.75 * orthoSca;
        cameraOrthoL.lookAt(cameraTarget);
        cameraOrthoL.updateProjectionMatrix();
        cameraPersp.position.set(-nCameraDistance, 0, 0);
        cameraPersp.rotation.set(1.57, -1.57, 0);
        cameraPersp.up.x = 0;
        cameraPersp.up.y = 0;
        cameraPersp.up.z = -1;
        cameraPersp.zoom = 0.75;
        cameraPersp.lookAt(cameraTarget);
        cameraPersp.updateProjectionMatrix();
        cameraOrtho.position.set(-nCameraDistance, 0, 0);
        cameraOrtho.rotation.set(1.57, -1.57, 0);
        cameraOrtho.up.x = 0;
        cameraOrtho.up.y = 0;
        cameraOrtho.up.z = -1;
        cameraOrtho.zoom = 0.75 * orthoSca;
        cameraOrtho.lookAt(cameraTarget);
        cameraOrtho.updateProjectionMatrix();
    }
    break;
    default: {
        displayType = 0;
        renderer.autoClear = true;
        cameraPersp.zoom = 1.0;
        cameraOrtho.zoom = orthoSca;
        SwitchView(3);
    }
    }
    controls.target.copy(controls.target0);
}

export const SetMultipleViewRender = function (type) {
    cancelAnimationFrame(animate);
    SetMultipleView(type);
    animate();
}

export const SetTrackballViewport = function (x, y) {
    if (controls != undefined) {
        switch (displayType) {
            case 1: {
                controls.object = 2.0 * x > ww ? camera : (camera === cameraPersp ? cameraPerspL : cameraOrthoL);
            }
            break;
        case 2: {
            controls.object = camera;
        }
        break;
        case 3: {
            controls.object = 2.0 * y > wh ?
                (2.0 * x > ww ? (camera === cameraPersp ? cameraPerspB : cameraOrthoB) : (camera === cameraPersp ? cameraPerspT : cameraOrthoT)) :
                (3.0 * x < ww ? (camera === cameraPersp ? cameraPerspL : cameraOrthoL) : (1.5 * x < ww ? camera : (camera === cameraPersp ? cameraPerspR : cameraOrthoR)));
        }
        break;
        default: {
            controls.object = camera;
        }
        }
        controls.panSpeed = (camera === cameraPersp) ? 0.3 : 1.5;
        controls.update();
        // RenderScene(renderer);
    }
}

export const ResetViewportTrackball = function (view) {
    if (controls != undefined) {
        let cameraP, cameraO;
        switch (displayType) {
            case 1: { // view: 'l', 'r'
                controls.object = (view === 'r') ? camera : (camera === cameraPersp ? cameraPerspL : cameraOrthoL);
                if (controls.object === camera) {
                    cameraPersp.position.set(0, 0, -nCameraDistance);
                    cameraPersp.rotation.set(0, 0, 0);
                    cameraPersp.up.x = 0;
                    cameraPersp.up.y = 1;
                    cameraPersp.up.z = 0;
                    cameraPersp.zoom = 0.75;
                    cameraPersp.lookAt(cameraTarget);
                    cameraPersp.updateProjectionMatrix();
                    cameraOrtho.position.set(0, 0, -nCameraDistance);
                    cameraOrtho.rotation.set(0, 0, 0);
                    cameraOrtho.up.x = 0;
                    cameraOrtho.up.y = 1;
                    cameraOrtho.up.z = 0;
                    cameraOrtho.zoom = 0.75 * orthoSca;
                    cameraOrtho.lookAt(cameraTarget);
                    cameraOrtho.updateProjectionMatrix();
                    cameraP = cameraPersp;
                    cameraO = cameraOrtho;
                } else {
                    cameraPerspL.position.set(0, 0, nCameraDistance);
                    cameraPerspL.rotation.set(0, 0, 0);
                    cameraPerspL.up.x = 0;
                    cameraPerspL.up.y = -1;
                    cameraPerspL.up.z = 0;
                    cameraPerspL.zoom = 0.75;
                    cameraPerspL.lookAt(cameraTarget);
                    cameraPerspL.updateProjectionMatrix();
                    cameraOrthoL.position.set(0, 0, nCameraDistance);
                    cameraOrthoL.rotation.set(0, 0, 0);
                    cameraOrthoL.up.x = 0;
                    cameraOrthoL.up.y = -1;
                    cameraOrthoL.up.z = 0;
                    cameraOrthoL.zoom = 0.75 * orthoSca;
                    cameraOrthoL.lookAt(cameraTarget);
                    cameraOrthoL.updateProjectionMatrix();
                    cameraP = cameraPerspL;
                    cameraO = cameraOrthoL;
                }
            }
            break;
        case 2: {
            cameraPersp.zoom = 0.75;
            cameraOrtho.zoom = 0.75 * orthoSca;
            SwitchView(3);
            cameraP = cameraPersp;
            cameraO = cameraOrtho;
        }
        break;
        case 3: { // view: 'tl', 'tm', 'tr', 'bl', 'br'
            switch (view) {
                case 'tl': {
                    controls.object = camera === cameraPersp ? cameraPerspL : cameraOrthoL;
                    cameraPerspL.position.set(nCameraDistance, 0, 0);
                    cameraPerspL.rotation.set(1.57, 1.57, 0);
                    cameraPerspL.up.x = 0;
                    cameraPerspL.up.y = 0;
                    cameraPerspL.up.z = -1;
                    cameraPerspL.zoom = 0.95;
                    cameraPerspL.lookAt(cameraTarget);
                    cameraPerspL.updateProjectionMatrix();
                    cameraOrthoL.position.set(nCameraDistance, 0, 0);
                    cameraOrthoL.rotation.set(1.57, 1.57, 0);
                    cameraOrthoL.up.x = 0;
                    cameraOrthoL.up.y = 0;
                    cameraOrthoL.up.z = -1;
                    cameraOrthoL.zoom = 0.95 * orthoSca;
                    cameraOrthoL.lookAt(cameraTarget);
                    cameraOrthoL.updateProjectionMatrix();
                    cameraP = cameraPerspL;
                    cameraO = cameraOrthoL;
                }
                break;
            case 'tm': {
                controls.object = camera;
                cameraPersp.position.set(0, -nCameraDistance, 0);
                cameraPersp.rotation.set(0, 0, 0);
                cameraPersp.up.x = 0;
                cameraPersp.up.y = 0;
                cameraPersp.up.z = -1;
                cameraPersp.zoom = 0.95;
                cameraPersp.lookAt(cameraTarget);
                cameraPersp.updateProjectionMatrix();
                cameraOrtho.position.set(0, -nCameraDistance, 0);
                cameraOrtho.rotation.set(0, 0, 0);
                cameraOrtho.up.x = 0;
                cameraOrtho.up.y = 0;
                cameraOrtho.up.z = -1;
                cameraOrtho.zoom = 0.95 * orthoSca;
                cameraOrtho.lookAt(cameraTarget);
                cameraOrtho.updateProjectionMatrix();
                cameraP = cameraPersp;
                cameraO = cameraOrtho;
            }
            break;
            case 'tr': {
                controls.object = camera === cameraPersp ? cameraPerspR : cameraOrthoR;
                cameraPerspR.position.set(-nCameraDistance, 0, 0);
                cameraPerspR.rotation.set(1.57, -1.57, 0);
                cameraPerspR.up.x = 0;
                cameraPerspR.up.y = 0;
                cameraPerspR.up.z = -1;
                cameraPerspR.zoom = 0.95;
                cameraPerspR.lookAt(cameraTarget);
                cameraPerspR.updateProjectionMatrix();
                cameraOrthoR.position.set(-nCameraDistance, 0, 0);
                cameraOrthoR.rotation.set(1.57, -1.57, 0);
                cameraOrthoR.up.x = 0;
                cameraOrthoR.up.y = 0;
                cameraOrthoR.up.z = -1;
                cameraOrthoR.zoom = 0.95 * orthoSca;
                cameraOrthoR.lookAt(cameraTarget);
                cameraOrthoR.updateProjectionMatrix();
                cameraP = cameraPerspR;
                cameraO = cameraOrthoR;
            }
            break;
            case 'bl': {
                controls.object = camera === cameraPersp ? cameraPerspT : cameraOrthoT;
                cameraPerspT.position.set(0, 0, nCameraDistance);
                cameraPerspT.rotation.set(0, 0, 0);
                cameraPerspT.up.x = 0;
                cameraPerspT.up.y = -1;
                cameraPerspT.up.z = 0;
                cameraPerspT.zoom = 0.95;
                cameraPerspT.lookAt(cameraTarget);
                cameraPerspT.updateProjectionMatrix();
                cameraOrthoT.position.set(0, 0, nCameraDistance);
                cameraOrthoT.rotation.set(0, 0, 0);
                cameraOrthoT.up.x = 0;
                cameraOrthoT.up.y = -1;
                cameraOrthoT.up.z = 0;
                cameraOrthoT.zoom = 0.95 * orthoSca;
                cameraOrthoT.lookAt(cameraTarget);
                cameraOrthoT.updateProjectionMatrix();
                cameraP = cameraPerspT;
                cameraO = cameraOrthoT;
            }
            break;
            case 'br': {
                controls.object = camera === cameraPersp ? cameraPerspB : cameraOrthoB;
                cameraPerspB.position.set(0, 0, -nCameraDistance);
                cameraPerspB.rotation.set(0, 0, 0);
                cameraPerspB.up.x = 0;
                cameraPerspB.up.y = 1;
                cameraPerspB.up.z = 0;
                cameraPerspB.zoom = 0.95;
                cameraPerspB.lookAt(cameraTarget);
                cameraPerspB.updateProjectionMatrix();
                cameraOrthoB.position.set(0, 0, -nCameraDistance);
                cameraOrthoB.rotation.set(0, 0, 0);
                cameraOrthoB.up.x = 0;
                cameraOrthoB.up.y = 1;
                cameraOrthoB.up.z = 0;
                cameraOrthoB.zoom = 0.95 * orthoSca;
                cameraOrthoB.lookAt(cameraTarget);
                cameraOrthoB.updateProjectionMatrix();
                cameraP = cameraPerspB;
                cameraO = cameraOrthoB;
            }
            break;
            default:
                return;
            }
        }
        break;
        default:
            return;
        }
        cameraRig.children.forEach(function (obj) {
            if (obj !== cameraP && obj !== cameraO) {
                if (obj.isPerspectiveCamera) {
                    obj.zoom = cameraP.zoom;
                } else if (obj.isOrthographicCamera) {
                    obj.zoom = cameraO.zoom;
                }
                obj.updateProjectionMatrix();
            }
        });
        controls.panSpeed = (camera === cameraPersp) ? 0.3 : 1.5;
        controls.target.copy(controls.target0);
        controls.update();
        RenderScene(renderer);
    }
}

export const GetCurrentStep = function () {
    return stepIndex;
}

export const IsSwitchViewEnabled = function () {
    return (displayType === 0 || displayType === 2);
}